import React from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { HomeAdminHeader } from '../HomeAdminHeader';
import { InfoFooter } from '../../InfoFooter';
import classes from './ManageUserProvince.module.css';
import favicon from '../../Images/faviconn.png';

function ManageUserProvinceUi({
  show,
  show2,
  handleShow4,
  handleSendPassword,
  handleSendPasswords,
  passwordLoading,
  handleSearch,
  isAdmin,
  pageNumber,
  setPageNumber,
  show1,
  handleShow,
  selectedParish,
  selectedParish1,
  handleParishChange,
  handleParishChange1,
  handleShow1,
  handleShow2,
  handleClose,
  handleClose1,
  handleClose2,
benLoading,
  createUser,
  parishName,
  setParishName,
  churchAddress,
  setChurchAddress,
  parishName1,
  setParishName1,
  churchAddress1,
  setChurchAddress1,
  editUser,
  isLoading,
  loading,
  loadings,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  allProvinces,
  handleClose4,
  currentPage,
  setCurrentPage,
  totalEntries,
  totalPages,
  startIndexx,
  endIndexx,
  displayedData,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  fullName,
  setFullName,
  address,
  setAddress,
  office_address,
  setOfficeAddress,
  phone,
  setPhone,
  email,
  setEmail,
  fullName1,
  setFullName1,
  setSelectedRole1,
  selectedRole1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  tableData,
  tableData9,
  tableData1,
  tableData4,
  tableData5,
  tableData6,
  tableData7,
selectedContinent,
selectedArea,
selectedProvince,
selectedZone,
selectedRegion,
handleAreaChange,
handleContinentChange,
handleProvinceChange,
handleZoneChange,
handleRegionChange,
  filteredData,
  handleNewUser,
  loaddd,
  transferLoading,
  handleTransferUser,
  handleShow3,
      handleClose3,
      show3,
      show4,
  generatedPassword,
  setGeneratedPassword,
  passwordGenerating,
  setPasswordGenerating,
  handleGeneratePassword,
  searchLoading,
  searchResult,
  fetchUsers,
  handleRoleChange,
  handleRoleChange11,
  selectedRole
}) {
  const formattedTotalEntries = totalEntries.toLocaleString();
  return (
    <div style={{ marginTop: '8rem' }}>
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <HomeAdminHeader />
            <div className='newBody'>
              <div className='newWidth'>

                {/* <!--Content Header (Page header)--> */}
                <div className="content-header row align-items-center m-0">
                {/* {!isAdmin && ( */}
                  <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                    >
                      <Button variant="success" onClick={handleShow4} >
                        Add New User
                      </Button>
                    </div>

                  </nav>
                {/* )} */}
                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        <h1 className="font-weight-bold">Manage Users</h1>
                        <small>Create and update your users...</small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--/.Content Header (Page header)--> */}
                <div className="body-content">
                  <div className="row">

                    <div className="col-lg-12 col-xl-6">
                      <div className="row">

                        <div className="col-md-6 col-lg-6">

                          {/* <!--Feedback--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Balance indicator--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Time on site indicator--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Top Referrals--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Sessions by device--> */}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                      <div className="card">


                      </div>
                    </div>




                    <Modal show={show4} onHide={handleClose4} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add user</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                        className="form-control"
                                        value={selectedRole}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" >Select Role</option>
                                        <option value="Provincial Admin" >Provincial Admin</option>
                                        <option value="Checker" >Checker</option>
                                        
                            </Form.Select>
                            {/* Conditional rendering based on selected role */}
                            <div style={{ marginTop: 10 }} />
                          {selectedRole === 'Checker' && (
                            <>
                            <Form.Label>Parish Name</Form.Label>
                            <Form.Select
                                  className="form-control"
                                  value={selectedParish}
                                  onChange={handleParishChange}
                              >
                                  <option value="" >Select Parish</option>
                                  {allProvinces.map((item) => (
                                  <option key={item.id} value={item.id}>
                                      {item.description}
                                  </option>
                                  ))}
                            </Form.Select>
                            </>
                          )}
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose4}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createUser}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Creating user, Please wait...</span>
    </>
  ) : (
                "Create User"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show3} onHide={handleClose3} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Generate Password</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Password"
                              // autoFocus
                              value={generatedPassword}
                              onChange={(e) => setGeneratedPassword(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose3}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={handleGeneratePassword}>
                    {passwordGenerating ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Generating password, Please wait...</span>
    </>
  ) : (
                "Generate Password"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="col-lg-12">
                      <div className="card">
                        {/* <div className="card-header">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 className="fs-17 font-weight-600 mb-0">Users List</h6>
                            </div>

                          </div>
                        </div> */}
                        <div className="card-body">
                          <div className="table-resposive">
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                              <div className={classes.greenbtn} style={{ display: 'flex', }}>
                                <div>
                                  <button>Copy</button>
                                  <button>Excel</button>
                                  <button>PDF</button>
                                  <button className={classes.diffbtn}>Column visibility</button>
                                </div>
                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
  <label htmlFor="pageNumber" style={{ marginRight: '10px' }}>Go to Page:</label>
  <input
    type="text"
    className="form-control form-control-sm"
    id="pageNumber"
    value={pageNumber}
    onChange={(e) => setPageNumber(e.target.value)}
    style={{ marginRight: '10px', width: '50px' }}
  />
  <button
  style={{ cursor: "pointer", border: 'none', backgroundColor: '#28a745',  height: '2.5rem', padding: 10, fontWeight: 500, fontSize: 14, color: '#fff', marginLeft: 10 }}
    onClick={() => {
      handleSendPasswords()
    }}
  >
    Send all users password
  </button>
  <button
  style={{ cursor: "pointer", border: 'none', backgroundColor: '#28a745',  height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, color: '#fff', marginLeft: 10 }}
    onClick={() => {
      if (pageNumber && !isNaN(pageNumber)) {
        const pageNum = parseInt(pageNumber);
        if (pageNum >= 1 && pageNum <= totalPages) {
          setCurrentPage(pageNum);
          setPageNumber('');
        } else {
          alert('Invalid page number');
        }
      } else {
        alert('Please enter a valid page number');
      }
    }}
  >
    Go
  </button>
</div> */}


                                

                              <div className="text-right modal-effect ">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div className="mr-2">Search:</div>
                                    {/* <input
                                      type="text"
                                      placeholder="Search..."
                                      value={searchTerm}
                                      onChange={handleSearch}
                                    /> */}
                                    {/* <input
                                      type="search"
                                      value={searchTerm}
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="DataTables_Table_0"
                                      onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        console.log('Search term:', e.target.value); // Add this line
                                      }}
                                    /> */}
                                    <input
    type="search"
    value={searchTerm}
    className="form-control form-control-sm"
    placeholder=""
    aria-controls="DataTables_Table_0"
    onChange={(e) => setSearchTerm(e.target.value)}
/>
<button
onClick={() => fetchUsers(searchTerm)}
  style={{ cursor: "pointer", border: 'none', backgroundColor: '#28a745',  
  height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, color: '#fff', marginLeft: 10 , justifyContent: "center", alignItems: "center", textAlign: "center"}}
  >
    {searchLoading ? (
    <>
      <Spinner size='sm'/>
    </>
  ) : (
    "Search"
  )}
  </button>
                                  </div>

                                </div>
                              </div>
                            </div>


                            {benLoading ? (
                              <p>Fetching users...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead >
                                    <tr>
                                      <th>ID</th>
                                      <th >Name</th>
                                      <th >Parish</th>
                                      <th>Role</th>
                                      <th>Email</th>
                                      <th>Phone Number</th>
                                      {/* <th>Region</th> */}
                                      {/* <th>Province</th> */}
                                      {/* <th>Area</th> */}
                                      
                                      {/* <th>Address</th> */}
                                      {/* {!isAdmin && ( */}
                                      <th>Actions</th>
                                      {/* )} */}
                                    </tr>
                                  </thead>
                                  <tbody >
                                  {searchResult.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.parish_name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone_number}</td>
                                        {/* <td>{item.region?.description}</td> */}
                                        {/* <td>{item.province?.description}</td>
                                        <td>{item.area?.description}</td> */}
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow3(item.id)}>
    <label style={{ cursor: 'pointer' }}>
      Generate Password
    </label>
  </div>
                                        


                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                            <i className="far fa-edit"></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                            <i className="far fa-trash-alt"></i>
                                          </div>
                                          <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow2(item.id)}>
                                            <label style={{ cursor: 'pointer' }} >
                                             Transfer User

                                            </label>
                                          </div>
                                          
                                        </td>
                                      </tr>
                                    ))}
                                  {tableData.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.parish_name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone_number}</td>
                                        {/* <td>{item.region?.description}</td> */}
                                        {/* <td>{item.province?.description}</td>
                                        <td>{item.area?.description}</td> */}
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow3(item.id)}>
    <label style={{ cursor: 'pointer' }}>
      Generate Password
    </label>
  </div>
                                        {item.password_sent === "0" ? (
  <div className="btn btn-primary-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleSendPassword(item.phone_number)}>
    <label style={{ cursor: 'pointer' }}>
      Send Password
    </label>
  </div>
) : null}


                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                            <i className="far fa-edit"></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                            <i className="far fa-trash-alt"></i>
                                          </div>
                                          <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow2(item.id)}>
                                            <label style={{ cursor: 'pointer' }} >
                                             Transfer User

                                            </label>
                                          </div>
                                          
                                        </td>
                                        {/* )} */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}

                            <Modal show={show2} onHide={handleClose2} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Transfer User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form style={{ marginTop: 20 }}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent}
                            onChange={handleContinentChange}
                            >
                              <option value="">Select Continent</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion}
                              onChange={handleRegionChange}
                            >
                              <option value="">Select Region</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince}
                              onChange={handleProvinceChange}
                            >
                            <option value="">Select Province</option>
                            {tableData5.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone}
                              onChange={handleZoneChange}
                            >
                            <option value="">Select Zone</option>
                            {tableData6.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Area</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedArea}
                              onChange={handleAreaChange}
                            >
                              <option value="">Select Area</option>
                            {tableData7.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Parish</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedParish}
                              onChange={handleParishChange}
                            >
                              <option value="">Select Parish</option>
                            {tableData9.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select>

                      </Form.Group>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose2}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={handleTransferUser}>
                      {transferLoading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Transferring, Please wait...</span>
                        </>
                      ) : (
                        "Transfer User"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>
                            <div className={classes.endded}>
                              <p>
                                Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>

                            <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />  
                                    <div style={{ marginTop: 10 }} />
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                        className="form-control"
                                        value={setSelectedRole1}
                                        onChange={handleRoleChange11}
                                    >
                                        <option value="" >Select Role</option>
                                        <option value="Provincial Admin" >Provincial Admin</option>
                                        <option value="Checker" >Checker</option>
                                        
                            </Form.Select>
                            {/* Conditional rendering based on selected role */}
                            <div style={{ marginTop: 10 }} />
                          {setSelectedRole1 == 'Checker' && (
                            <>
                            <Form.Label>Parish Name</Form.Label>
                            <Form.Select
                                  className="form-control"
                                  value={selectedParish1}
                                  onChange={handleParishChange1}
                              >
                                  <option value="" >Select Parish</option>
                                  {allProvinces.map((item) => (
                                  <option key={item.id} value={item.id}>
                                      {item.description}
                                  </option>
                                  ))}
                            </Form.Select>
                            </>
                          )}
                                     {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Parish Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Parish Name"
                              autoFocus
                              value={parishName1}
                              onChange={(e) => setParishName1(e.target.value)}
                            /> */}
                            {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Church Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Church Address"
                              autoFocus
                              value={churchAddress1}
                              onChange={(e) => setChurchAddress1(e.target.value)}
                            />                                   */}
                                    
                                  </Form.Group>
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editUser} >{loadings ? 
                                (
                                  <>
                                  <Spinner  size='sm' /> 
                                  <span style={{ marginLeft: '5px' }}>Updating, Please wait...</span>
                                  </>
                                ) : (
                                "Save Changes"
                                  )
                                }
                                </Button>
                            </Modal.Footer>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        <InfoFooter />
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>
    </div>
  );
}

export default ManageUserProvinceUi;
