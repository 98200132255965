import {  Route, Routes  } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Admin from './Admin/Admin';
import Login from './Pages/Login/Login';
import Pricing from './Pages/Pricing/Pricing';
import ManageContinent from './Admin/Manage-Continent/ManageContinent';
import ChangePassword from './Admin/Change password/ChangePassword';
import ForgotPassword from './Pages/Forgot Password/Forgot_password';
import Provence from './Admin/Provence/Provence';
import Region from './Admin/Manage Region/Region';
import Zone from './Admin/Manage Zone/Zone';
import Area from './Admin/Manage Area/Area';
import Parish from './Admin/Manage-Parish/Parish';
import CreateFixed from './Admin/Manage Fixed Asset/CreateFixed';
import AssetsCategory from './Admin/Manage Assets Category/AssetsCategory';
import FixedAssetsClassSummary from './Admin/Fixed-Assets-Class-Summary/FixedAssetsClassSummary';
import AssetsSubcategory from './Admin/Manage Assets Subcategory/AssetsSubcategory';
import CreateFixedAsset from './Admin/Manage Fixed Asset/CreateFixedAsset';
import AssetsSubcategoryDisp from './Admin/Manage Assets Subcategory disposal/AssetsSubcategoryDisp';
import ManageUser from './Admin/Manage-user/ManageUser';
import FixedAssetReport from './Admin/Manage Fixed Asset Report/FixedAssetReport';
import EditFixedAssetRegister from './Admin/Manage Fixed Asset/EditFixedAssetRegisterAdmin';
import ActivityReports from './Admin/Activity-Report/ActivityReports';
import CreateUser from './Admin/Manage-user/CreateUser';
import TransferReport from './Admin/Manage Fixed Asset Report/TransferReport';
import TrfReports from './Admin/Manage Fixed Asset Report/TrfReports';
import EditUser from './Admin/Manage-user/EditUser';
import ActivityReport from './Admin/Activity-Report/ActivityReport';
import Home1 from './Admin/Homes/Home1';
// import Home2 from './Admin/Homes/FixedAssetsReg';
import FixedAssetsReg from './Admin/Homes/FixedAssetsReg';
import EditFixedAssetReg from './Admin/Homes/EditFixedAssetReg';
import ManageUserProvince from './Admin/Manage-user province/ManageUserProvince';
import CreateProvince from './Admin/Provence/CreateProvince';
import CheckerHome from './Admin/Checker/checkerHome';
import ManageUserChecker from './Admin/Checker/ManageUserChecker';
import AssetsSubcategoryCheckerDisp from './Admin/Checker/AssetsSubcategoryCheckerDisp';
import AssetsSubcategoryInitiatorDisp from './Admin/Initiator/AssetsSubcategoryInitiatorDisp';
import InitiatorHome from './Admin/Initiator/InitiatorHome';
import ManageUserInitiator from './Admin/Initiator/ManageUserInitiator';
import CreateFixedAssetInitiator from './Admin/Initiator/CreateFixedAssetsInitiator';
import UploadFixedAssetsInitiator from './Admin/Initiator/UploadFixedAssetsInitiator';
import EditFixedAssetRegisterChecker from './Admin/Checker/EditFixedAssetRegisterChecker';
import EditFixedAssetRegisterInitiator from './Admin/Initiator/EditFixedAssetRegisterInitiator';
import ProvincialAdmin from './Admin/ProvincialAdmin';
import ManageUserAdmin from './Admin/Manage-user admin/ManageUserAdmin';
import ZoneAdmin from './Admin/Manage Zone admin/ZoneAdmin';
import AreaAdmin from './Admin/Manage Area admin/AreaAdmin';
import ParishAdmin from './Admin/Manage-Parish admin/ParishAdmin';
import FixedAssetsClassSummaryAdmin from './Admin/Fixed-Assets-Class-Summary admin/FixedAssetsClassSummaryAdmin';
import FixedAssetReportAdmin from './Admin/Manage Fixed Asset Report/FixedAssetReportAdmin';
import TransferReportAdmin from './Admin/Manage Fixed Asset Report/TransferReportAdmin';
import EditFixedAssetRegisterAdmin from './Admin/Manage Fixed Asset/EditFixedAssetRegisterAdmin';
import EditFixedAssetRegisterProvincialAdmin from './Admin/EditFixedAssetRegisterProvincialAdmin';
import RegionalHome from './Admin/Regional Accountant/RegionalHome';
import ManageUserRegional from './Admin/Regional Accountant/ManageUserRegional';
import CreateUserRegional from './Admin/Regional Accountant/CreateUserRegional';
import EditUserRegional from './Admin/Regional Accountant/EditUserRegional';
import FixedAssetsRegRegional from './Admin/Regional Accountant/FixedAssetsRegRegional';
import EditFixedAssetRegional from './Admin/Regional Accountant/EditFixedAssetRegional';
import AssetsSubcategoryCheckerDispUi from './Admin/Regional Accountant/AssetsRegionalDisposalUi';
import AssetsRegionalDisposal from './Admin/Regional Accountant/AssetsRegionalDisposal';
import EditUser1 from './Admin/Manage-user/EditUser1';
import ZoneAdmin1 from './Admin/Manage Zone admin1/ZoneAdmin1';
import AreaAdmin1 from './Admin/Manage Area admin1/AreaAdmin1';
import ParishAdmin1 from './Admin/Manage-Parish admin1/ParishAdmin1';
import FixedAssetReportAdmin1 from './Admin/Manage Fixed Asset Report/FixedAssetReportAdmin1';
import TransferReportAdmin1 from './Admin/Manage Fixed Asset Report/TransferReportAdmin1';
import ParishAdmin2 from './Admin/Regional Accountant/ParishAdmin2';
import FixedAssetReportAdmin2 from './Admin/Regional Accountant/FixedAssetReportAdmin2';
import TransferReportAdmin2 from './Admin/Regional Accountant/TransferReportAdmin2';
import NotFound from './Admin/NotFound';
import ContinentHome from './Admin/Continental Accountant/ContinentalHome';
import ManageUserContinental from './Admin/Continental Accountant/ManageUserContinental';
import EditUserContinental from './Admin/Continental Accountant/EditUserContinental';
import CreateUserContinent from './Admin/Continental Accountant/CreateUserContinent';
import FixedAssetRegContinental from './Admin/Continental Accountant/FixedAssetsRegContinental';
import EditFixedAssetContinental from './Admin/Continental Accountant/EditFixedAssetContinental';
import AssetsContinentDisposal from './Admin/Continental Accountant/AssetsContinentDisposal';
import FixedAssetReportAdmin3 from './Admin/Continental Accountant/FixedAssetReportAdmin3';
import ParishAdmin3 from './Admin/Continental Accountant/ParishAdmin3';
import TransferReportAdmin3 from './Admin/Continental Accountant/TransferReportAdmin3';
import EditUserProvince from './Admin/Manage-user province/EditUserProvince';
import ManageDeletedUser from './Admin/Manage-deleted-user/ManageDeletedUser';
import EditFixedAssetRegisterProvincial from './Admin/EditFixedAssetRegisterProvincial';
import ParishFixedRegister from './Admin/Homes/ParishFixedRegister';
import ProvinceCompliance from './Admin/Manage Fixed Asset Report/ProvinceCompliance';
import EditFixedAssetRegisterRegional from './Admin/Regional Accountant/EditFixedAssetRegisterRegional';
import EditFixedAssetRegisterContinental from './Admin/Regional Accountant/EditFixedAssetRegisterContinental';

function App() {
  // const [userType, setUserType] = useState(null);

  // AsyncStorage.getItem("userType")
  // .then((type) => {
  //   setUserType(type);
  // })
  // .catch((error) => {
  //   console.error('Error retrieving user type:', error);
  // });
  

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
 
      <Routes>
        <Route path='/'element={<Login/>}/>
        <Route path='/route_not_found'element={<NotFound/>}/>
        <Route path='/login'element={<Login/>}/>
        <Route path='/admin'element={<Admin/>}/>
        <Route path='/province'element={<Provence/>}/>
        <Route path='/create_fixed'element={<CreateFixedAsset/>}/>
        <Route path='/manage_continent'element={<ManageContinent/>}/>
       <Route path='/region' element={<Region/>}/>
       <Route path='/create_fixed_asset'element={<CreateFixed/>}/>
       <Route path='/activity_report'element={<ActivityReports/>}/>
       <Route path='/activity_reports'element={<ActivityReport/>}/>
        <Route path='/pricing'element={<Pricing/>}/>
        <Route path='/manage_user'element={<ManageUser/>}/>
        <Route path='/forgot_password'element={<ForgotPassword/>}/>
        <Route path='/change_password'element={<ChangePassword/>}/>
        <Route path='/fixed_asset_report'element={<FixedAssetReport/>}/>
        <Route path='/province_compliance_report'element={<ProvinceCompliance/>}/>
        <Route path='/zone'element={<Zone/>}/>
        <Route path='/area'element={<Area/>}/>
        <Route path='/parish'element={<Parish/>}/>
        <Route path='/assets_category'element={<AssetsCategory/>}/>
        <Route path='/assets_subcategory'element={<AssetsSubcategory/>}/>
        <Route path='/assets_subcategoryDisp'element={<AssetsSubcategoryDisp/>}/>
        <Route path='/fixed_assets_class_summary'element={<FixedAssetsClassSummary/>}/>
        <Route path='/edit_fixed_assets_register_admin'element={<EditFixedAssetRegisterAdmin/>}/>
        <Route path='/edit_fixed_assets_register_provincial'element={<EditFixedAssetRegisterProvincial/>}/>
        <Route path='/create_user'element={<CreateUser/>}/>
        <Route path='/transfer_report'element={<TransferReport/>}/>
        <Route path='/trf_report'element={<TrfReports/>}/>
        <Route path='/edit_user'element={<EditUser/>}/>
        <Route path='/edit_user_province'element={<EditUserProvince/>}/>
        <Route path='/edit_user1'element={<EditUser1/>}/>
        <Route path='/home'element={<Home1/>}/>
        <Route path='/fixed_assets_register'element={<FixedAssetsReg/>}/>
        <Route path='/view_parish_fixed_register'element={<ParishFixedRegister/>}/>
        <Route path='/view_fixed_assets'element={<EditFixedAssetReg/>}/>
        <Route path='/manage_user_province'element={<ManageUserProvince/>}/>
        <Route path='/create_province'element={<CreateProvince/>}/>
        <Route path='/provincial_admin'element={<ProvincialAdmin/>}/>
        <Route path='/provincial_users'element={<ManageUserAdmin/>}/>
        <Route path='/provincial_zones'element={<ZoneAdmin/>}/>
        <Route path='/provincial_zones_accountant'element={<ZoneAdmin1/>}/>
        <Route path='/provincial_areas_accountant'element={<AreaAdmin1/>}/>
        <Route path='/provincial_areas'element={<AreaAdmin/>}/>
        <Route path='/provincial_parishes_accountant'element={<ParishAdmin1/>}/>
        <Route path='/regional_parish'element={<ParishAdmin2/>}/>
        <Route path='/continent_parish'element={<ParishAdmin3/>}/>
        <Route path='/provincial_parishes'element={<ParishAdmin/>}/>
        <Route path='/provincial_fixed_assets_class_summary'element={<FixedAssetsClassSummaryAdmin/>}/>
        <Route path='/provincial_fixed_asset_report_accountant'element={<FixedAssetReportAdmin1/>}/>
        <Route path='/provincial_fixed_asset_report'element={<FixedAssetReportAdmin/>}/>
        <Route path='/regional_fixed_asset_report'element={<FixedAssetReportAdmin2/>}/>
        <Route path='/continent_fixed_asset_report'element={<FixedAssetReportAdmin3/>}/>
        <Route path='/provincial_transfer_report'element={<TransferReportAdmin/>}/>
        <Route path='/regional_transfer_report'element={<TransferReportAdmin2/>}/>
        <Route path='/view_asset_regional'element={<EditFixedAssetRegisterRegional/>}/>
        <Route path='/view_asset_continental'element={<EditFixedAssetRegisterContinental/>}/>
        <Route path='/continent_transfer_report'element={<TransferReportAdmin3/>}/>
        <Route path='/provincial_transfer_report_accountant'element={<TransferReportAdmin1/>}/>
        <Route path='/checker_home'element={<CheckerHome/>}/>
        <Route path='/manage_user_checker'element={<ManageUserChecker/>}/>
        <Route path='assets_subcategory_checker'element={<AssetsSubcategoryCheckerDisp/>}/>
        {/* {userType === 'initiator' && (
        <> */}
        <Route path='initiator_home'element={<InitiatorHome/>}/>
        <Route path='assets_subcategory_initiatior'element={<AssetsSubcategoryInitiatorDisp/>}/>
        <Route path='manage_user_initiator'element={<ManageUserInitiator/>}/>
        <Route path='create_fixed_assets_inititor'element={<CreateFixedAssetInitiator/>}/>
        <Route path='upload_fixed_assets_initiator'element={<UploadFixedAssetsInitiator/>}/>
        <Route path='edit_fixed_assets_checker'element={<EditFixedAssetRegisterChecker/>}/>
        <Route path='edit_fixed_assets_initiator'element={<EditFixedAssetRegisterInitiator/>}/>
{/* </>
        )} */}
        <Route path='edit_fixed_assets_register_provincial_admin'element={<EditFixedAssetRegisterProvincialAdmin/>}/>
        <Route path='regional_home'element={<RegionalHome/>}/>
        <Route path='manage_deleted_user'element={<ManageDeletedUser/>}/>
        <Route path='regional_users'element={<ManageUserRegional/>}/>
        <Route path='regional_users_view'element={<EditUserRegional/>}/>
        <Route path='regional_create_users'element={<CreateUserRegional/>}/>
        <Route path='regional_fixed_asset'element={<FixedAssetsRegRegional/>}/>
        <Route path='regional_view_fixed_asset'element={<EditFixedAssetRegional/>}/>
        <Route path='regional_view_disposed_asset'element={<AssetsRegionalDisposal/>}/>
        <Route path='edit_fixed_asset_register'element={<EditFixedAssetRegister/>}/>
        <Route path='continent_home'element={<ContinentHome/>}/>
        <Route path='continent_users'element={<ManageUserContinental/>}/>
        <Route path='continent_users_view'element={<EditUserContinental/>}/>
        <Route path='continent_create_users'element={<CreateUserContinent/>}/>
        <Route path='continent_fixed_asset'element={<FixedAssetRegContinental/>}/>
        <Route path='continent_view_fixed_asset'element={<EditFixedAssetContinental/>}/>
        <Route path='continent_view_disposed_asset'element={<AssetsContinentDisposal/>}/>
        {/* <Route path='edit_fixed_asset_register'element={<EditFixedAssetRegister/>}/> */}
      
      </Routes>
    </>
  );
}
export default App;