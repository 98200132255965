import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';
import classes from './ActivityReports.module.css';

export default function ActivityReports() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `Date Time: ${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { tableData } = location.state || {};

    // Group data by category_id
    useEffect(() => {
        if (tableData && tableData.length > 0) {
            const grouped = tableData.reduce((acc, item) => {
                const categoryID = item.category_id;
                if (!acc[categoryID]) {
                    acc[categoryID] = [];
                }
                acc[categoryID].push(item);
                return acc;
            }, {});
            setGroupedData(Object.entries(grouped));
        }
    }, [tableData]);

    return (
        <div className={classes.reportBody}>
            <div style={{ marginTop: '2rem' }} />

            <div className={classes.a42}>
                <div className={classes.header}>
                    <p style={{ width: '100%' }}>{company}</p>
                    {/* <h4>Account Activity Period, 01 - 01 (September - September) 2017/2018</h4> */}
                    <h4>Fixed Assets Register </h4>
                </div>
                <p>{currentDateTime}</p>

               
                   

                        <table className={classes.table2}>
                            <thead>
                                <tr>
                                    <th >ID No.</th>
                                    <th >Assets Name</th>
                                    <th>Model / Type</th>
                                    <th>Serial Number</th>
                                    <th>Quantity</th>
                                    <th>Amount Purchased</th>
                                    <th>Date Purchased</th>
                                    <th>Net Book Value</th>
                                    {/* <th>Location</th> */}
                                    {/* <th>Lifetime in years</th> */}
                                    {/* <th>Residual Value</th> */}
                                    {/* <th>Date Disposed</th> */}
                                    {/* <th>Proceed On Sale</th> */}
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                           
                            {groupedData.map(([categoryID, items]) => (
                            <tbody key={categoryID}>
                                <tr style={{  fontWeight: "bold", whiteSpace: "nowrap", marginTop:'50px',}}> {items[0].asset_category.description}</tr>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{width: '50px',}}>{item.identification_number}</td>
                                        <td style={{width: "200px", whiteSpace: "nowrap"}}>{item.description}</td>
                                        <td style={{width: "200px", whiteSpace: "nowrap", textAlign: "left"}}>{item.model_number}</td>
                                        <td style={{width: "100px", }}>{item.serial_number}</td>
                                        <td style={{width: "200px", }}>{item.quantity}</td>
                                        <td style={{ textAlign: "right" }}>{parseFloat(item.amount_purchased).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}</td>
                                        <td>{item.date_purchased}</td>
                                        <td>{item.net_book_value}</td>
                                        {/* <td>{item.location}</td> */}
                                        {/* <td style={{ textAlign: "left" }}>{item.lifetime_in_years}</td> */}
                                        {/* <td style={{ textAlign: "left" }}>{item.residual_value}</td> */}
                                        {/* <td>{item.date_disposed}</td> */}
                                        {/* <td style={{ textAlign: "right" }}>{parseFloat(item.proceed_on_sale === null ? "0.00" : item.proceed_on_sale).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}</td> */}
                                        <td>{item.remarks}</td>
                                    </tr>
                                ))}
                                
                               <tr className={classes.totalSec}>
                                <td  colSpan="5" >Total</td>
                                <td  style={{textAlign:'right', fontWeight: "bold"}}>
                                    {parseFloat(
                                        items.reduce((acc, item) => acc + parseFloat(item.amount_purchased), 0)
                                    ).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </td>
                                {/* <td colSpan="3" ></td>
                                <td  style={{textAlign:'right'}}>
                                    {parseFloat(
                                        items.reduce((acc, item) => acc + (parseFloat(item.proceed_on_sale) || 0), 0)
                                    ).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </td> */}
                            </tr>
                            </tbody>
                             ))}
                            
                        </table>
            </div>
        </div>
    );
}
