 import React, { useState, useEffect } from 'react';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { InfoFooter } from '../../InfoFooter';
import { AdminHeaderNav } from '../AdminHeaderNav';
import classes from '../../Admin/Manage Fixed Asset/CreateFixed.module.css';
import favicon from '../../Images/faviconn.png'
import { InitiatorHeaderNav } from './InitiatorHeaderNav';

function UploadFixedAssetsInitiator() {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [show1, setShow1] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
    const [remarks, setRemarks] = useState('');
const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    setSelectedFile(null);
  };

 

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [identification, setIdentification] = useState("");
  const [assetName, setAssetName] = useState("");
  const [amountPurchased, setAmountPurchased] = useState('');
  const [lifeTime, setLifeTime] = useState('');
    const [proceed, setProceed] = useState('');
    const [residualValue, setResidualValue] = useState('');
    const [address, setAddress] = useState('');
    const [selectedDate1, setSelectedDate1] = useState('');
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFiles1, setSelectedFiles1] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Validate if the selected file is of type .xls
    if (file && file.name.endsWith('.xlsx, .xls')) {
      setSelectedFile(file);
    } else {
      // Handle invalid file type
      console.error('Invalid file type. Please select a .xls, .xlsx file.');
      setSelectedFile(null);
    }
  };


 
  const uploadDocument = async () => {
    setLoad(true);
  
    try {
      const formData = new FormData();
     
      formData.append('category_id', selectedCategory);
      formData.append('file', selectedFiles1[0]);
      
     
  
      const response = await axios.post(
        'https://asset-api.patna.ng/api/fixedassets/import',
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      console.log(response.data.message);
      handleClose1();
     
      setSelectedFiles1([]);
      navigate('/initiator_home');

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
  
      console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
              }
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorMessage,
              });
      console.log(error);
    } finally {
      setLoad(false);
    }
  };

  const handleClose1 = () => {
    setShow1(false);
    setSelectedFile1(null);
  };
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleFileChange1 = (event) => {
    const files = event.target.files;
    setSelectedFiles1(Array.from(files));
  };
    
  const handleDateChange1 = (event) => {
    setSelectedDate1(event.target.value);
  };


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchAssets = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/fixedassets', { headers });
      const results = response.data?.data;
      setTableData(results);
      // console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchAssets();

    }
  }, [bearer]);



  
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  

  //   navigate(`/edit_fixed_assets_register/${id}`, { state: { assetData: foundAsset } });
  // };
  const handleEyeClick = (id) => {
const foundAsset = tableData.find(item => item.id === id);
    navigate('/edit_fixed_assets_register', { state: { selectedAsset: foundAsset } });
    setEyeClicked(true);
  };

  const handleTrashClick = async (id) => {
    try {
      const response = await axios.get(`https://api-sme.promixaccounting.com/api/v1/destroy?id=${id}`, { headers });
      // fetchBooking();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  };

 

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/assetcategories', 
      { headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      }}
);

      const cat = response.data?.data;
      setCategories(cat);
      console.log(cat);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setCategories([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchCategories();

    }
  }, [bearer]);


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleCreate = () => {
    navigate('/create_fixed');
  };
  const handleUpload = () => {
    navigate('/income');
  };


  return (

    <div className='generalWrapper'>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <InitiatorHeaderNav/>
            <div className='newBody'>
              <div className='newWidth'>

                {/* <!--Content Header (Page header)--> */}
                <div className="content-header row align-items-center m-0">

                  
                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        <h1 className="font-weight-bold">Upload Asset Register</h1>
                        <small>Upload your registers...</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='download-template'>
                        <a href="https://asset-api.patna.ng/api/download-template" download>
                          <Button variant="primary" style={{ margin: '0', borderRadius: 0 }}>
                            Download Template
                          </Button>
                        </a>
                      </div>

                <Modal show={show} onHide={handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Upload your Register</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form style={{ marginTop: 20 }}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Category</Form.Label>
                        <Form.Select onChange={(e) => handleCategoryChange(e)} value={selectedCategory}>
                          <option value="">Select a category</option>
                          {categories.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Form.Select>

                        <Form.Label style={{ marginTop: 20 }}>Upload Register</Form.Label>
                        <Form.Control type="file" accept=".xlsx, .xls" onChange={handleFileChange1} />
                      </Form.Group>
                    </Form>

                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={uploadDocument}>
                      {load ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Uploading your document, Please wait...</span>
                        </>
                      ) : (
                        "Upload Asset Register"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                

                {/* <!--/.Content Header (Page header)--> */}
                <div className="body-content">
                  

                  
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-resposive">
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                            <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 upload-fix">
                    <div >
                      
                      <div
                        style={{
                          // marginTop: 20,
                          // marginBottom: 20,
                          // justifyContent: "flex-end",
                          // display: "flex",
                          // marginLeft: "auto",
                        }}
                      >
                        <Button variant="success" style={{ borderRadius: 0 }} onClick={handleShow}>
                          Click here to upload new register
                        </Button>
                      </div>
                      

                    </div>

                  </nav>
                             
                            </div>




                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--/.body content--> */}
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          <InfoFooter />
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default UploadFixedAssetsInitiator;