import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../src/InfoFooter.module.css'

export const InfoFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="footer-content">
                <div className={classes.infoFotCont}>
                    <div className={classes.copy}>© {currentYear} BSRV Professionals, Providing Payment Solutions for Nigerians</div>
                    <div className={classes.copy}>Designed by: <NavLink to="#">BPSL</NavLink></div>
                </div>
            </footer>
        </div>
    );
}
