import React, { useState, useEffect } from 'react';
// import Happiness from '../../src/Images/happiness.svg';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import happiness from "../../Admin/assets/dist/img/happiness.svg";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import "../style.css";
import { AdminHeaderNav } from '../AdminHeaderNav';
import { AdminHeadernavMainMenu } from '../AdminHeadernavMainMenu';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import { InfoFooter } from '../../InfoFooter';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import classes from './Home1.module.css';
import favicon from '../../Images/faviconn.png'
import PieChart1 from '../../Images/Pie-Chart-1.png'
import AsyncStorage from '@react-native-async-storage/async-storage';
import  {Badge} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { HomeAdminHeader } from '../HomeAdminHeader';



function ParishFixedRegister() {
  const navigate = useNavigate();
  const [selectedFiles1, setSelectedFiles1] = useState(null);
    const [user, setUser] = useState('');
    const [selectedAssetId, setSelectedAssetId] = useState('');
    const [bearer, setBearer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assetLoading, setAssetLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [loaddd, setLoaddd] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [show1, setShow1] = useState(false);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedParish, setSelectedParish] = useState('');
  const [show2, setShow2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [tableData8, setTableData8] = useState([]);
  const [tableData9, setTableData9] = useState([]);
  const location = useLocation();
  const { responseData1 } = location?.state || {};
  console.log(responseData1, "afterrrrs");
  const handleClose1 = () => {
    setShow1(false);
   
  };
  const handleClose2 = () => {
    setShow2(false);
   
  };
  
  const handleGoBack = () =>{
    navigate(-1);
  }

  const handleFileChange1 = (event) => {
    const files = event.target.files;
    setSelectedFiles1(Array.from(files));
  };

  const handleShow1 = (id) => {
    setSelectedAssetId(id);
    setShow1(true);
  };

  const handleShow2 = (id) => {
    setSelectedAssetId(id);
    setShow2(true);
  };

  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
   setSelectedZone("");
   setSelectedArea("");
   setSelectedParish("");
  }
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
   setSelectedProvince("");
  
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
   setSelectedZone("");
  }
  const handleZoneChange = (event) =>{
    setSelectedZone(event.target.value);
   setSelectedArea("");
    
  }
  const handleParishChange = (event) =>{
    setSelectedParish(event.target.value);
    
  }
  const handleAreaChange = (event) =>{
    setSelectedArea(event.target.value);
   setSelectedParish("");
  }


  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('tobi');
      const details = await AsyncStorage.getItem('userToken');

      if (detail !== null) {
        setUser(detail);
      }


      if (details !== null) {
        setBearer(details);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const filteredData = responseData1.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };




  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

      const fetchAssets = async () => {
        setAssetLoading(true);
        try {
          const response = await axios.get('https://asset-api.patna.ng/api/fixedassets', { headers });
          const results = response.data?.data;
          setTableData(results);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            
            // navigate('/login');
          } else {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            setTableData([]);
          }
        } finally {
          setAssetLoading(false);
        }
      };
    
    
    
      useEffect(() => {
        if (bearer) {
          fetchAssets();
    
        }
      }, [bearer]);


  const handleEyeClick = (id) => {
    const foundAsset = responseData1.find(item => item.id === id);
    navigate('/edit_fixed_asset_register', { state: { selectedAsset: foundAsset } });
    setEyeClicked(true);
  };

          const uploadDocument = async () => {
            setLoad(true);
          
            try {
              const formData = new FormData();
             
              formData.append('asset_id', selectedAssetId);
              selectedFiles1.forEach((file, index) => {
                formData.append(`asset_document[${index}]`, file);
              });
             
          
              const response = await axios.post(
                'https://asset-api.patna.ng/api/fixedassets/upload_document',
                formData,
                {
                  headers: {
                    ...headers,
                    'Content-Type': 'multipart/form-data',
                  },
                }
              );
          
              console.log(response.data.message);
              handleClose1();
             
              setSelectedFiles1([]);
              fetchAssets();
        
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
              });
          
              console.log(response.data);
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              console.log(error);
            } finally {
              setLoad(false);
            }
          };

          const fetchContinents = async () => {
            setIsLoading(true);
            try {
              const response = await axios.get('https://asset-api.patna.ng/api/continents', { headers });
        
              // console.log(response);
              const resultsss = response.data?.data;
              setTableData1(resultsss);
              // console.log(results);
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData1([]);
            } finally {
              setIsLoading(false);
            }
          };
          useEffect(() => {
            if (bearer) {
          
              fetchContinents();
            }
          }, [bearer]);

          const fetchSubContinents = async (selectedContinent) => {
            setIsLoading(true);
            try {
              const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
              { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            }, });
        
              // console.log(response);
              const subCont = response.data?.data;
              setTableData4(subCont);
              // console.log(subCont, "heeeer");
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData4([]);
            } finally {
              setIsLoading(false);
            }
          };
        
          
        
          const fetchSubRegions = async (selectedRegion) => {
            setIsLoading(true);
            try {
              const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
              { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            }, });
        
              // console.log(response);
              const subReg = response.data?.data;
              setTableData5(subReg);
              // console.log(subCont, "heeeer");
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData5([]);
            } finally {
              setIsLoading(false);
            }
          };
        
          
          const fetchSubProvinces = async (selectedProvince) => {
            setIsLoading(true);
            try {
              const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getzonesprovince?province_id=${selectedProvince}`, 
              { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            }, });
        
              // console.log(response);
              const subReg = response.data?.data;
              setTableData6(subReg);
              // console.log(subCont, "heeeer");
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData6([]);
            } finally {
              setIsLoading(false);
            }
          };
        
          
        
          const fetchSubZones = async (selectedZone) => {
            setIsLoading(true);
            try {
              const response = await axios.get(`https://asset-api.patna.ng/api/zones/getareaszone?zone_id=${selectedZone}`, 
              { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            }, });
        
              // console.log(response);
              const subReg = response.data?.data;
              setTableData7(subReg);
              // console.log(subCont, "heeeer");
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData7([]);
            } finally {
              setIsLoading(false);
            }
          };

          const fetchSubAreas = async (selectedArea) => {
            setIsLoading(true);
            try {
              const response = await axios.get(`https://asset-api.patna.ng/api/areas/getparishesarea?area_id=${selectedArea}`, 
              { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            }, });
        
              // console.log(response);
              const subAreaa = response.data?.data;
              setTableData9(subAreaa);
              // console.log(subCont, "heeeer");
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              setTableData9([]);
            } finally {
              setIsLoading(false);
            }
          };
        
          
        
          useEffect(() => {
            if (bearer && selectedContinent) {
              fetchSubContinents(selectedContinent);
            }
          }, [bearer, selectedContinent]);

          useEffect(() => {
            if (bearer && selectedArea) {
              fetchSubAreas(selectedArea);
            }
          }, [bearer, selectedArea]);
        
          useEffect(() => {
            if (bearer && selectedRegion) {
              fetchSubRegions(selectedRegion);
            }
          }, [bearer, selectedRegion]);
        
          useEffect(() => {
            if (bearer && selectedProvince) {
              fetchSubProvinces(selectedProvince);
            }
          }, [bearer, selectedProvince]);
        
          useEffect(() => {
            if (bearer && selectedZone) {
              fetchSubZones(selectedZone);
            }
          }, [bearer, selectedZone]);
        
          const createProvince = async () => {
            setLoaddd(true);
            try {
              const response = await axios.post(
                'https://asset-api.patna.ng/api/fixedassets/transfer',
                {
                  continent_id: selectedContinent,
                  region_id: selectedRegion,
                  area_id: selectedArea,
                  province_id: selectedProvince,
                  asset_id: selectedAssetId,
                  zone_id: selectedZone,
                  parish_id: selectedParish

                 
                },
                { headers }
              );
              // console.log(response)
              fetchAssets();
              handleClose2();
              // return
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
              });
              console.log(response.data);
        
            } catch (error) {
              let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
              console.log(error);
            } finally {
              setLoaddd(false);
            }
          };
        
          
        

  return (

    // <div className="fixed">
    <div style={{ marginTop: '8rem', }}>
      {/* <!-- #END# Page Loader --> */}
      <div></div>
      <div className="wrapper">

        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            {/* <AdminHeaderNav /> */}
            <AdminHeaderNav/>


            {/* <!--Content Header (Page header)--> */}
            <div className='newBody'>
              <div className='newWidth'>
                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-12 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        {/* <h1 className="font-weight-bold"> Welcome, {user} </h1> */}
                        <h1 className="font-weight-bold"> Fixed Asset Register</h1>
                        <small>View fixed assets register.</small>  
                      </div>
                      <div
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    justifyContent: "flex-end",
                                                    display: "flex",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <Button variant="success" onClick={handleGoBack}>
                                                    Go Back
                                                {/* <i class="fa fa-long-arrow-left" aria-hidden="true"></i> */}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 header-title p-0">
                                        <div className="media">
                                        {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                                            <div className="media-body" style={{marginTop: 20}}>
                                                {/* <h1 className="font-weight-bold"> Fixed Asset Register </h1> */}
                                                {/* <small>From now on you will start your activities.</small> */}
                                            </div>
                                            {/* <div
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    justifyContent: "flex-end",
                                                    display: "flex",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <Button variant="success" onClick={handleShow}>
                                                    Create New Company
                                                </Button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="body-content">

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-resposive">
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                              <div className={classes.greenbtn} style={{ display: 'flex', }}>
                                {/* <div>
                                  <button>Copy</button>
                                  <button>Excel</button>
                                  <button>PDF</button>
                                  <button className={classes.diffbtn}>Column visibility</button>
                                </div> */}
                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className={classes.showentries} style={{ display: 'flex', }}>
                                {/* <div>
                                  <button>Copy</button>
                                  <button>Excel</button>
                                  <button>PDF</button>
                                  <button className={classes.diffbtn}>Column visibility</button>
                                </div> */}
                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              
                              <div className="mobile-viewsearch " style={{ display: 'none !important' }}>
                                <div id="" className="">
                                  <div className="d-flex justify-content-start align-items-center" style={{ display: 'none !important' }}>
                                    <div className="mr-2">Search:</div>
                                    <input
                                      type="search"
                                      value={searchTerm}
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="DataTables_Table_0"
                                      onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        // setCurrentPage(1);
                                      }}
                                    />
                                  </div>

                                </div>
                              </div>
                            </div>


                            {assetLoading ? (
                              <p>Fetching register...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead >
                                  <tr>
                                      <th>S/N</th>
                                      <th>Identification Number</th>
                                      <th>Asset Name</th>
                                      
                                      <th>Category</th>
                                      <th>Quantity</th>
                                      <th>Approval Status</th>
                                      
                                      
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                    {displayedData.map((item, index) => (
                                       <tr key={index}>
                                       <td>{index + 1}</td>
                                       <td>{item.identification_number}</td>
                                       <td>{item.description}</td>
                                       
                                       <td>{item.asset_category?.description}</td>
                                       <td>{item.quantity}</td>
                                       <td>
 <Badge style={{padding :10}} bg={
   item.approval_status === "0" ? 'warning' :
   item.approval_status === "1" ? 'success' :
   item.approval_status === "2" ? 'success' :
   item.approval_status === "3" ? 'danger' : ''
 }>
   {
     item.approval_status === "0" ? "Pending Approval" :
     item.approval_status === "1" ? "Checker Approved" :
     item.approval_status === "2" ? "Provincial Approved" :
     item.approval_status === "3" ? "Disapproved" :
     ""
   }
 </Badge>
</td>

                                       
                                       
                                       <td style={{ whiteSpace: 'nowrap' }}>
                                       <div onClick={() => handleEyeClick(item.id)}  className="btn btn-success-soft btn-sm mr-1">
                                           <i className="far fa-eye"></i>
                                         </div>
                                         {/* {
 item.approval_status === "0" && (
   <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
     <i className="far fa-trash-alt"></i>
   </div>
 )
} */}
                                         {/* <div className="btn btn-primary-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow1(item.id)}>
                                           <label htmlFor="fileInput" style={{ cursor: 'pointer' }} >
                                             Upload your file

                                           </label>
                                         </div> */}
                                         {/* <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleShow2(item.id)}>
                                           <label htmlFor="fileInput" style={{ cursor: 'pointer' }} >
                                            Transfer Asset

                                           </label>
                                         </div> */}

                                       </td>
                                     </tr>
                                    ))}

                                  </tbody>
                                </table>
                              </div>
                            )}

                            <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Upload your Document</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload Document</Form.Label>
                                    <Form.Control type="file" accept=".pdf, .jpeg, .jpg, .png" onChange={handleFileChange1} multiple />

                                  </Form.Group>
                                </Form>
                              </Modal.Body>

                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose1}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={uploadDocument}>
                      {load ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Uploading your document, Please wait...</span>
                        </>
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

<Modal show={show2} onHide={handleClose2} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Transfer Asset</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form style={{ marginTop: 20 }}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent}
                            onChange={handleContinentChange}
                            >
                              <option value="">Select Continent</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion}
                              onChange={handleRegionChange}
                            >
                              <option value="">Select Region</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince}
                              onChange={handleProvinceChange}
                            >
                            <option value="">Select Province</option>
                            {tableData5.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone}
                              onChange={handleZoneChange}
                            >
                            <option value="">Select Zone</option>
                            {tableData6.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Area</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedArea}
                              onChange={handleAreaChange}
                            >
                              <option value="">Select Area</option>
                            {tableData7.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Parish</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedParish}
                              onChange={handleParishChange}
                            >
                              <option value="">Select Parish</option>
                            {tableData9.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select>

                      </Form.Group>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose2}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={createProvince}>
                      {loaddd ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Transferring, Please wait...</span>
                        </>
                      ) : (
                        "Transfer Asset"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                            <div className={classes.endded}>
                              <p>
                                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h6 className="fs-17 font-weight-600 mb-0">All Employees</h6>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className={classes.belowheader}>
                                                    <div className={classes.greenbtn}>
                                                        <div>
                                                            <button>Copy</button>
                                                            <button>Excel</button>
                                                            <button>PDF</button>
                                                            <button className={classes.diffbtn}>Column visibility</button>
                                                        </div>
                                                    </div>
                                                    <div className={classes.searchside}>
                                                        <label className={classes.onlylabel}>Search:</label>
                                                        <input className={classes.thesearch} type="search" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
            {/* <!--/.body content--> */}
          </div>
          <InfoFooter />
          {/* <div className="overlay"></div> */}
        </div>
      </div>



    </div>

  );
}

export default ParishFixedRegister;