import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import ParishUi from './ParishAdminUi';
import { useNavigate } from 'react-router-dom';



function ParishAdmin() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [totalEntries, setTotalEntries] = useState("");
  const [churchAddress, setChurchAddress] = useState("");
  const [churchAddress1, setChurchAddress1] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [tableData8, setTableData8] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  // const [permissions, setPermissions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [parishLoading, setParishLoading] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');

  const [description, setDescription] = useState('');
  const [description1, setDescription1] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedProvince1, setSelectedProvince1] = useState('');
  const [selectedRegion1, setSelectedRegion1] = useState('');
  const [selectedZone1, setSelectedZone1] = useState('');
  const [selectedArea1, setSelectedArea1] = useState('');
  const [selectedParish, setSelectedParish] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
        setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  //fetch records
  const fetchParishes = async () => {
    setParishLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getaparishprovince`, { headers });

      // console.log(response);
      const paris = response.data?.data;
      const tot = response.data?.data?.parishes?.total;
      setTotalEntries(tot);
      setTableData8(paris);
      // console.log(paris, "here");
      const total = response.data?.data?.parishes?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }else{
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData8([]);
      }
    } finally {
      setParishLoading(false);
    }
  };


  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/provinces/getazoneprovince', { headers });

      // console.log(response);
      const resultsss = response.data?.data;
      setTableData1(resultsss);
      // console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchParishes();
      
    }
  },  [bearer, ]);

  useEffect(() => {
    if (bearer) {
  
      fetchContinents();
    }
  }, [bearer]);

  const fetchSubContinents = async (selectedContinent) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubContinents1 = async (setSelectedContinent1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubRegions = async (selectedRegion) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubRegions1 = async (selectedRegion1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubProvinces = async (selectedProvince) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getzonesprovince?province_id=${selectedProvince}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData6(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData6([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubProvinces1 = async (selectedProvince1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getzonesprovince?province_id=${selectedProvince1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData6(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData6([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubZones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getareaprovince`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData7(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData7([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubZones1 = async (selectedZone1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/zones/getareaszone?zone_id=${selectedZone1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData7(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData7([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegions(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer && selectedProvince) {
      fetchSubProvinces(selectedProvince);
    }
  }, [bearer, selectedProvince]);

  useEffect(() => {
    if (bearer) {
      fetchSubZones();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer && selectedContinent1) {
      fetchSubContinents1(selectedContinent1);
    }
  }, [bearer, selectedContinent1]);

  useEffect(() => {
    if (bearer && selectedRegion1) {
      fetchSubRegions1(selectedRegion1);
    }
  }, [bearer, selectedRegion1]);

  useEffect(() => {
    if (bearer && selectedProvince1) {
      fetchSubProvinces1(selectedProvince1);
    }
  }, [bearer, selectedProvince1]);

  useEffect(() => {
    if (bearer && selectedZone1) {
      fetchSubZones1(selectedZone1);
    }
  }, [bearer, selectedZone1]);

  //create beneficiary
  const createParishes = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/parishes/create_by_admin',
        {
          description: description,
          zone_id: selectedZone,
          area_id: selectedArea,
          church_address: churchAddress
         
        },
        { headers }
      );
      console.log(response)
      fetchParishes();
      handleClose();
      setSelectedContinent('');
      setSelectedRegion("");
      setSelectedProvince("");
      setSelectedZone("");
      setSelectedArea("");
      setDescription("");
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
   setSelectedZone("");
   setSelectedArea("");
  }
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value)
  }
  const handleZoneChange = (event) =>{
    setSelectedZone(event.target.value)
  }
  const handleParisChange = (event) =>{
    setSelectedZone(event.target.value)
  }
  const handleAreaChange = (event) =>{
    setSelectedArea(event.target.value)
  }

  const handleContinentChange1 = async (event) => {
    setSelectedContinent1(event.target.value);
   setSelectedRegion1("");
   setSelectedProvince1("");
   setSelectedZone1("");
   setSelectedArea1("");
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value)
  }
  const handleProvinceChange1 = (event) => {
    setSelectedProvince1(event.target.value)
  }
  const handleZoneChange1 = (event) =>{
    setSelectedZone1(event.target.value)
  }
  const handleParisChange1 = (event) =>{
    setSelectedZone1(event.target.value)
  }
  const handleAreaChange1 = (event) =>{
    setSelectedArea1(event.target.value)
  }

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {

    const foundParish = tableData8.find(item => item.id === id);
setSelectedParish(foundParish);
setDescription1(foundParish?.description || '');
    setShow1(true);
    setEyeClicked(true);
  };


  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this parish. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/parishes/delete_parish?id=${id}`, { headers });
      fetchParishes();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

  //update function
  const editParish = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/parishes/update_parish',
        {
          area_id : selectedArea1,
          description: description1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          region_id: selectedRegion1,
          id : selectedParish.id,
          zone_id: selectedZone1
         
          
        },
        { headers }
      );
handleClose1();
      fetchParishes();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData8.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);




  return (
    <ParishUi
      // Pass necessary props from the logic to the UI component
      show={show}
      show1={show1}
      handleShow={handleShow}
      handleShow1={handleShow1}
      handleClose={handleClose}
      handleClose1={handleClose1}
      createParishes={createParishes}
      // editUser={editUser}
      isLoading={isLoading}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      entriesPerPage={entriesPerPage}
      setEntriesPerPage={setEntriesPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalEntries={totalEntries}
      totalPages={totalPages}
      startIndexx={startIndexx}
      endIndexx={endIndexx}
      displayedData={displayedData}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleEyeClick={handleEyeClick}
      handleTrashClick={handleTrashClick}
      fullName={fullName}
      setFullName={setFullName}
      address={address}
      setAddress={setAddress}
      office_address={office_address}
      setOfficeAddress={setOfficeAddress}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      fullName1={fullName1}
      setFullName1={setFullName1}
      phone1={phone1}
      setPhone1={setPhone1}
      email1={email1}
      setEmail1={setEmail1}
      tableData={tableData}
      tableData8={tableData8}
      editParish={editParish}

      // tableData={tableData}
      description = {description}
      setDescription = {setDescription}
      // selected  = {selected }
      selectedContinent = {selectedContinent}
      setSelectedContinent = {setSelectedContinent}
      selectedRegion = {selectedRegion}
      setSelectedRegion = {setSelectedRegion}
      selectedProvince = {selectedProvince}
      setSelectedProvince = {setSelectedProvince}
      selectedZone = {selectedZone}
      selectedArea = {selectedArea}
      selectedContinent1 = {selectedContinent1}
     churchAddress={churchAddress}
     setChurchAddress={setChurchAddress}
     churchAddress1={churchAddress1}
     setChurchAddress1={setChurchAddress1}
      selectedRegion1 = {selectedRegion1}
      
      selectedProvince1 = {selectedProvince1}
      
      selectedZone1 = {selectedZone1}
      selectedArea1 = {selectedArea1}
      
      handleZoneChange = {handleZoneChange}
      handleContinentChange = {handleContinentChange}
      handleRegionChange = {handleRegionChange}
      handleProvinceChange = {handleProvinceChange}
      handleAreaChange = {handleAreaChange}
      handleZoneChange1 = {handleZoneChange1}
      handleContinentChange1 = {handleContinentChange1}
      handleRegionChange1 = {handleRegionChange1}
      handleProvinceChange1 = {handleProvinceChange1}
      handleAreaChange1 = {handleAreaChange1}
      handleParisChange = {handleParisChange}
      formatDate = {formatDate}
      tableData1={tableData1}
      tableData2={tableData2}
      tableData3={tableData3}
      tableData4={tableData4}
      tableData5={tableData5}
      tableData6={tableData6}
      tableData7={tableData7}
      description1={description1}
      setDescription1={setDescription1}
     parishLoading={parishLoading}
      // tableData={tableData7}
    />
  )
}

export default ParishAdmin;