import React, {useState, useEffect} from 'react'
import classes from './ChnagePassword.module.css'
import { AdminHeaderNav } from '../AdminHeaderNav'
import { InfoFooter } from '../../InfoFooter'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
    const [bearer, setBearer] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          

          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          

        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
      
      useEffect(() => {
        readData();
      }, []); 

    const handleChangePassword = async () => {
        setLoading(true);
        try {
          const response = await axios.post(
            'https://asset-api.patna.ng/api/change-password',
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`
              }
            }
          );
      
          navigate('/login');
      
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
      
          // console.log(response.data);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

    return (
        <div className="fixed">
       
       <div className="wrapper">
        {/* <!-- Sidebar  --> */}
        
        
        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
            <div className="main-content">
   
            <AdminHeaderNav />
                <div className={classes.headed}>
                    <div>
                        <p className={classes.ptag}>Change Password</p>
                        <div className={classes.sndptg}>
                            <p className={classes.adcmptag}>Change Password </p>
                            <div className={classes.thehr}><hr className={classes.goated}></hr> <hr className={classes.goat}></hr></div>
                        </div>
                    </div>
                </div>
                <div className={classes.compform}>
                    <div>
                        <div className={classes.cmpname}>
                            <label className={classes.inptptg}>Current Password</label>
                            <input className={classes.inputcmpname} placeholder='Enter your current password' type='password' ></input>
                        </div>
                        <div className={classes.cmpname}>
                            <label className={classes.inptptg}>Create New Password</label>
                            <input className={classes.inputcmpname} placeholder='Enter New Password' type='password'></input>
                        </div>
                        <div className={classes.cmpname}>
                            <label className={classes.inptptg}>Confirm New Password</label>
                            <input className={classes.inputcmpname} placeholder='Confirm New Password' type='password' ></input>
                        </div>
                        
                        <button className={classes.addcmpnybtn}>Change Password</button>
                    </div>

                </div>


            </div>
            <InfoFooter />

        </div>
        </div>
        </div>
    )
}

export default ChangePassword