import React, { useState, useEffect } from 'react';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import classes from '../../Admin/Manage Fixed Asset Report/FixedAssetReport.module.css';
import { AdminHeaderNav } from '../AdminHeaderNav';

import { InfoFooter } from '../../InfoFooter';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Spinner, Modal, Form } from 'react-bootstrap';
import favicon from '../../Images/faviconn.png'

// import CurrencyInput from 'react-currency-input-field';

function ProvinceCompliance() {
  const [showFilteredData, setShowFilteredData] = useState(false);
    const [displayedItems, setDisplayedItems] = useState([]);
    const handleShow1 = () => setShow1(true);
    // const [description, setDescription] = useState('');
    const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
    const [selectedCreditAccount, setSelectedCreditAccount] = useState('');
    const [selectedBookingId, setSelectedBookingId] = useState('');
    const [entriesPerPage, setEntriesPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [displayedItemsFired, setDisplayedItemsFired] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState("");
    const [outstanding, setOutstanding] = useState('');
    const [bookingId, setBookingId] = useState([]);
    const [creditAcc, setCreditAcc] = useState([]);
    const [debitAcc, setDebitAcc] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const [tableData4, setTableData4] = useState([]);
    const [tableData5, setTableData5] = useState([]);
    const [tableData6, setTableData6] = useState([]);
    const [tableData7, setTableData7] = useState([]);
    const [tableDat8, setTableData8] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedContinent, setSelectedContinent] = useState('');
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedParish, setSelectedParish] = useState('');
    const [eyeClicked, setEyeClicked] = useState(false);

    const [show1, setShow1] = useState(false);
    const [selectedFile1, setSelectedFile1] = useState(null);

    const handleClose1 = () => {
        setShow1(false);
        setSelectedFile1(null); 
      };


    // const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

    // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

   

    const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    // const totalEntries = filteredData.length;
    const startIndexx = (currentPage - 1) * entriesPerPage + 1;
    const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
    // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

   

   

    const fetchContinents = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://asset-api.patna.ng/api/continents', { headers });
    
          // console.log(response);
          const resultsss = response.data?.data;
          setTableData1(resultsss);
          // console.log(results);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData1([]);
        } finally {
          setIsLoading(false);
        }
      };

      const fetchSubContinents = async (selectedContinent) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subCont = response.data?.data;
          setTableData4(subCont);
          // console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData4([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      const fetchSubRegions = async (selectedRegion) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subReg = response.data?.data;
          setTableData5(subReg);
          // console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData5([]);
        } finally {
          setIsLoading(false);
        }
      };
      const fetchSubProvinces = async (selectedProvince) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getzonesprovince?province_id=${selectedProvince}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subProv = response.data?.data;
          setTableData6(subProv);
          // console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData6([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      const fetchSubZones = async (selectedZone) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/zones/getareaszone?zone_id=${selectedZone}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subZone = response.data?.data;
          setTableData7(subZone);
          // console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData7([]);
        } finally {
          setIsLoading(false);
        }
      };
      const fetchSubArea = async (selectedArea) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/zones/getareaszone?zone_id=${selectedArea}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subAre = response.data?.data;
          setTableData8(subAre);
          // console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setTableData8([]);
        } finally {
          setIsLoading(false);
        }
      };



      const handleContinentChange = async (event) => {
        setSelectedContinent(event.target.value);
        setSelectedRegion("");
        setSelectedProvince("");
      }

      const handleRegionChange = (event) => {
        setSelectedRegion(event.target.value)
      }
      const handleProvinceChange = (event) => {
        setSelectedProvince(event.target.value)
      }
      const handleZoneChange = (event) =>{
        setSelectedZone(event.target.value)
      }
    //   const handleParisChange = (event) =>{
    //     setSelectedZone(event.target.value)
    //   }
      const handleAreaChange = (event) =>{
        setSelectedArea(event.target.value)
      }

      const handleParishChange = (event) =>{
        setSelectedParish(event.target.value)
      }
    
    // const handleDebitChange = (event) => {
    //     setSelectedDebitAccount(event.target.value);
    // };
    // const handleAccountChange = (event) => {
    //     setSelectedCreditAccount(event.target.value);
    // };


    const fetchAssets = async (page) => {
        setLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/get-province-parish-statistics?province_id=${selectedProvince}&page=${page}`, { headers });
          const results = response.data?.data?.data;
          const resultx = response.data?.data?.total;
          setTotalEntries(resultx);
          setTableData(results);
          const total = response.data?.data?.last_page || 1;
          setTotalPages(total);
          console.log(total);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            
          setTableData([]);
          }
        } finally {
          setLoading(false);
        }
      };
    
    
      useEffect(() => {
        if (bearer) {
            // fetchSales();
            
            fetchContinents();
           
        }
      }, [bearer]);
   

   
    
      


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');

            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }

    
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    
    const handleNextPage = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    useEffect(() => {
      fetchAssets(currentPage);
    }, [currentPage]);
  


    

    useEffect(() => {
        if (bearer && selectedContinent) {
          fetchSubContinents(selectedContinent);
        }
      }, [bearer, selectedContinent]);
    
      useEffect(() => {
        if (bearer && selectedRegion) {
          fetchSubRegions(selectedRegion);
        }
      }, [bearer, selectedRegion]);
    
      useEffect(() => {
        if (bearer && selectedProvince) {
          fetchSubProvinces(selectedProvince);
        }
      }, [bearer, selectedProvince]);
    
      useEffect(() => {
        if (bearer && selectedZone) {
          fetchSubZones(selectedZone);
        }
      }, [bearer, selectedZone]);

      useEffect(() => {
        if (bearer && selectedArea) {
          fetchSubArea(selectedArea);
        }
      }, [bearer, selectedArea]);

      

      // const fetchFilteredAsset = async () => {
      //   setLoad(true);
    
      //   try {
      //     const response = await axios.get(
      //       'https://asset-api.patna.ng/api/fixedassets/all-approved-parish-assets',
      //       {
      //         params: {
      //           parish_id: selectedParish
      //         },
      //         headers: {
      //           'Content-Type': 'application/json',
      //           'Authorization': `Bearer ${bearer}`
      //         }
      //       }
      //     );
    
      //     const displayedAsset = response.data?.data;
      //     setDisplayedItems(displayedAsset);
      //     setShowFilteredData(true);
      //     setDisplayedItemsFired(true);
      //     console.log(displayedAsset);
    
      //   } catch (error) {
      //     const errorStatus = error.response.data.message;
      //     console.error(errorStatus);
      //   } finally {
      //     setLoad(false);
      //   }
      // };

    const renderTableCells = (item, index) => {
      return (
          <>
              <td>{index + 1}</td>
              <td>{item.identification_number}</td>
              <td>{item.description}</td>
              <td>{item.model_number}</td>
              <td>{item.serial_number}</td>
              <td>{item.asset_category?.description}</td>
              <td>{item.quantity}</td>
              <td>{item.date_purchased}</td>
              <td>{parseFloat(item.unit_price).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })}</td>
              <td style={{ textAlign: "right" }}>{parseFloat(item.amount_purchased).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })}</td>
              <td>{item.location}</td>
              <td>{item.lifetime_in_years}</td>
              <td>{parseFloat(item.net_book_value).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })}</td>
              <td>{item.residual_value}</td>
              <td>{item.date_disposed}</td>
              <td style={{ textAlign: "right" }}>
                  {parseFloat(item.proceed_on_sale === null ? "0.00" : item.proceed_on_sale).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  })}
              </td>
              <td>{item.remarks}</td>
          </>
      );
  };
  
  const handleEyeClick = (id) => {
    const foundAsset = tableData.find(item => item.id === id);
    navigate('/edit_fixed_asset_register', { state: { selectedAsset: foundAsset } });
    setEyeClicked(true);
  };


  const downloadUrl = `https://asset-api.patna.ng/api/download-province-parish-statistics?province_id=${selectedProvince}`;
  

    return (
        <div style={{marginTop:'8rem',}}>
            <AdminHeaderNav />
            <div className='newBody'>
                <div className='newWidth'>
                    <div className="wrapper">
                        {/* <!-- Sidebar  --> */}


                        {/* <!-- Page Content  --> */}
                        <div className="content-wrapper">



                            <div className="main-content">
                                

                                <div className="content-header row align-items-center m-0">

                                    <div className="col-sm-8 header-title p-0">
                                        <div className="media">
                                            <div className="header-icon text-success mr-3"><i className=""><img src={favicon} style={{ height: 30, width: 30 }} alt="favicon" /></i></div>
                                            <div className="media-body" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", minWidth:'900px', }}>
                                                <div>
                                                    <h1 className="font-weight-bold">Province Compliance Report </h1>
                                                    <small>Complete the respective fields ....</small>
                                                </div>
                                                {/* <div style={{ marginBottom: 30 }}>
                                                    <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                                                </div> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                          

                            <div className="body-content">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="create-new-staff-card-header">
                                            <div className="d-flex justify-content-between align-items-center">

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-body">


                                                            <div className="row" style={{marginTop: 20}}>
                                                            <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Continent</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedContinent} onChange={handleContinentChange}>
                                                                                <option value="">Choose continent</option>
                                                                                {tableData1.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description} 
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Region</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedRegion} onChange={handleRegionChange}>
                                                                                <option value="">Choose Region</option>
                                                                                {tableData4.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description}
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Province</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedProvince} onChange={handleProvinceChange}>
                                                                                <option value="">Choose province</option>
                                                                                {tableData5.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description} 
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Zone</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedZone} onChange={handleZoneChange}>
                                                                                <option value="">Choose zone</option>
                                                                                {tableData6.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description}
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Area</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedArea} onChange={handleAreaChange}>
                                                                                <option value="">Choose area</option>
                                                                                {tableData7.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description}
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            <div className="col-md-4">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Select Parish</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="account" className="form-control" required="" value={selectedParish} onChange={handleParishChange}>
                                                                                <option value="">Choose parish</option>
                                                                                {tableDat8.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.description}
                                                                                </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                            
                                                                <div className="row justify-content-center" style={{marginTop: 30}}>
    <div className="col-md-4 text-center" > 
      <div className="form-group row">
        <Button style={{borderRadius: 2}} variant='success' onClick={() => fetchAssets(currentPage)} download>
          {loading ? (
            <>
              <Spinner size='sm'/>
              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
            </>
          ) : (
            "Process"
          )}
        </Button>
      </div>
    </div>
  </div>

                                                                
                                                                
                                                                
                                                                



                                                            </div>

                                                            
                                                            

                                                           

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">

                                <div className="card-body">
                                    <div className="table-resposive">
                                        <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                                            <div className={classes.greenbtn} style={{ display: 'flex', }}>
                                            {tableData && tableData.length > 0 && (
                                              <a href={downloadUrl} download>
            <button  style={{ height: 30, width: 150, borderRadius: 5 }}>
                Download Excel
            </button>
            </a>
        )}
        


                                               
                                                <div>
                                                    <label className="d-flex justify-content-start align-items-center">
                                                        Show
                                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                                            onChange={(e) => {
                                                                setEntriesPerPage(parseInt(e.target.value));
                                                                setCurrentPage(1);
                                                            }}>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        entries
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-right modal-effect ">
                                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="mr-2">Search:</div>
                                                        <input
                                                            type="search"
                                                            value={searchTerm}
                                                            className="form-control form-control-sm"
                                                            placeholder=""
                                                            aria-controls="DataTables_Table_0"
                                                            onChange={(e) => {
                                                                setSearchTerm(e.target.value);
                                                                // setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        {loading ? (
  <p>Fetching register...</p>
) : (
  <div className="table-responsive">
    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
      <thead>
        <tr>
          <th>Parish</th>
          <th>Registered</th>
          <th>Approved</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item) => (
          <tr>
            <td>{item.parish}</td>
            <td>{item.registered}</td>
            <td>{item.approved}</td>
            <td>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

                         
                                        <div className={classes.endded}>
                              <p>
                                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>



                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <InfoFooter />
        </div>
    )
}

export default ProvinceCompliance;