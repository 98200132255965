import React, {useState, useEffect} from 'react';
import classes from './Login.module.css';
import { Link } from 'react-router-dom';
import Navigation from '../Nav/Navigation';
import { useNavigate, useLocation } from 'react-router-dom';
import Signup from '../SignUp/SignUp';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Swal from "sweetalert2";
import {Spinner, Button} from 'react-bootstrap';
import axios from "axios";
import crossedEyeIcon from '../../Images/eye-slash.png';

function Login() {
    const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [loading, setLoading] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [showError, setShowError] = useState(false);
const navigate = useNavigate();
const location = useLocation();
const [showPassword, setShowPassword] = useState(false);
const [passwordLoading, setPasswordLoading] = useState(false);

const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  // const handleLogin = async () => {
  //   setLoading(true); 

  //   try {
  //     const response = await axios.post('https://api-payroll.promixaccounting.com/api/login', {
  //       email: email,
  //       password: password,
  //     });
  //     console.log(response, "After");
  //     AsyncStorage.setItem('userToken', response.data?.data?.token);
  //     AsyncStorage.setItem('userName', response.data?.data?.user?.name);
  //     AsyncStorage.setItem('userEmail', response.data?.data?.user?.email);
  //     AsyncStorage.setItem('userPhone', response.data?.data?.user?.phone_no);
  //     console.log(response.data?.user?.name);
  
  
  //     if (location.state && location.state.from) {
  //       navigate(location.state.from);
  //     } else {
       
  //       navigate('/admin');
  //     }
  
  //     console.log('Login successful', response?.data?.message);
  //     setEmail('');
  //     setPassword('');
  
  //   } catch (error) {
  //     const errorMessage = JSON.stringify(error.response?.message || 'An error occurred');
  //     setErrorMessage(errorMessage);
  
  //     console.error('Login failed', error.response?.message);
      
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Login failed',
  //       text: error.response?.data?.message || 'An error occurred',
  //     });
  
  //   } finally {
  //     setLoading(false);
  //   }
  // };
 
  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://asset-api.patna.ng/api/login', {
        email: email,
        password: password
      });

      const isAdmin = response.data?.data?.user?.is_admin === "1";
      const isProvicionalAccountant = response.data?.data?.user?.model_id === "3";
      const provinceName = response.data?.data?.user?.province?.description;
      const isChecker = response.data?.data?.user?.model_id === "1";
      const modelId = response.data?.data?.user?.model_id;
      const companyName = isAdmin ? "ADMIN" : response.data?.data?.user?.parish_name;
      const provinceAdmin = isProvicionalAccountant ? response.data?.data?.user?.model : "ADMIN";
      const userType = response.data?.data?.user?.type;
      const result = response.data?.data?.user?.name;
      const resultx = response.data?.data?.user?.email;
      const results = response.data?.data?.token;
      console.log(response, "LOGIN");

      AsyncStorage.setItem('userType', userType);
      AsyncStorage.setItem('companyName', companyName);
      AsyncStorage.setItem('provinceName', provinceName);
      AsyncStorage.setItem('checker', isChecker);
      AsyncStorage.setItem('provinceAdmin', provinceAdmin);
      AsyncStorage.setItem('modelUser', modelId);
      AsyncStorage.setItem('admin', isAdmin);
      AsyncStorage.setItem('tobi', result);
      AsyncStorage.setItem('userToken', results);
      AsyncStorage.setItem('userEmail', resultx);
      AsyncStorage.setItem('provinceAccountant', isProvicionalAccountant);
  
      switch(userType) {
        case 'Super Admin':
          navigate('/admin');
          break;
          case 'Provincial Admin':
          navigate('/provincial_admin');
          break;
        case 'Provincial Accountant':
          navigate('/home');
          break;
        case 'Accountant':
          navigate('/home');
          break;
        case 'Checker':
          navigate('/checker_home');
          break;
        case 'Initiator':
          navigate('/initiator_home');
          break;
        case 'Regional Accountant':
          navigate('/regional_home');
          break;
        case 'Continental Accountant':
          navigate('/continent_home');
          break;
        case 'Continental Admin':
          navigate('/continent_home');
          break;
        case 'Regional Admin':
          navigate('/regional_home');
          break;
        default:
          navigate('/login');
      }
    } catch (error) {
      let errorMessage = error.response?.data?.message || 'An error occurred';
      if (error.message === 'Network Error') {
          errorMessage = 'Connection error. Please check your internet connection.';
      }
      setErrorMessage(errorMessage);
      Swal.fire({
          icon: 'error',
          title: 'Login failed',
          text: errorMessage,
      });
  } finally {
      setLoading(false);
  }
}
  
   
    const SignUp = () =>{
        navigate('/signup')
     }

     const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !isButtonDisabled) {
        handleLogin();
      }
    };

    const isButtonDisabled = !email || !password;
    // console.log(email);
    const handleSendPassword = async () => {
      setPasswordLoading(true);
      console.log(email);
      try {
        // Send request to send password
        const response = await axios.post(
            `https://asset-api.patna.ng/api/password?phone_number`,
            {phone_number: email},
            { headers: {
                'Content-Type': 'application/json',
              }}
        );
    
        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
       
       
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
              if (error.response && error.response.data && error.response.data.message) {
                  if (typeof error.response.data.message === 'string') {
                      errorMessage = error.response.data.message;
                  } else if (Array.isArray(error.response.data.message)) {
                      errorMessage = error.response.data.message.join('; ');
                  } else if (typeof error.response.data.message === 'object') {
                      errorMessage = JSON.stringify(error.response.data.message);
                  }
              }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
    
        console.error(error);
      } finally {
        setPasswordLoading(false);
      }
    };

  return (
    <div>
        <Navigation/>
        <div className={classes.body}>
            <div className={classes.main}>
                <div className={classes.Login}>
                    <div className={classes.LoginHeader}>
                        <h2>Log in </h2>
                        <p> Welcome back! Please enter your details.</p>
                    </div>
                    <form>
                        <div>
                            <label>Email/Phone Number</label><br/>
                            <input type='email' className={classes.formInput} placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} ></input>
                        </div>
                        <div>
                            <label>Password</label><br/>
                            <div style={{alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                            <input type={showPassword ? 'text' : 'password'} className={classes.formInput}  placeholder='Enter your password' onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress}></input>
                            </div>
                            <button
                  type="button"
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    float: 'right',
                    left: "-10px",
                    marginTop: '-45px',
                    position: 'relative',
                    zIndex: 2
                  }}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                  ) : (
                    '👁️'
                  )}
                </button>
                            </div>
                        </div>
                        <div className={classes.section}>
                            <span>
                                {/* <input type='radio'></input>
                                <p>Remember for 30 days</p> */}
                            </span>
                            <Link to={'/forgot_password'} className={classes.link}>Forgot password</Link>
                        </div>
                       
                        <Button className={classes.SignInBtn} onClick={handleLogin} disabled={isButtonDisabled}>
  {loading ? (
    <>
      <Spinner size='lg'/>
      <span style={{ marginLeft: '5px' }}>Signing in, Please wait...</span>
    </>
  ) : (
    "Sign in"
  )}
</Button>


{/* <Button className={classes.SignInBtn1} onClick={handleSendPassword} >
  {passwordLoading ? (
    <>
      <Spinner size='lg'/>
      <span style={{ marginLeft: '5px' }}>Processing, please wait...</span>
    </>
  ) : (
     "Don't have Password? Click here!"
  )}
</Button> */}

                    </form>
                    {/* <span className={classes.dntHvAcct}>
                        <p>Don’t have an account?</p>
                        <span onClick={SignUp}>Sign up</span>
                    </span> */}
                </div>
            </div>
            
        </div>

    </div>
  )
}

export default Login