import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import RegionUi from './RegionUi';   
// import AssetsSubcategoryUi from './AssetsSubcategoryUiDisp';
import { useNavigate } from 'react-router-dom';
import AssetsContinentDisposalUi from './AssetsContinentDisposalUi';



function AssetsContinentDisposal() {
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');
  
  const [description, setDescription] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');   

  const [selectedAssets, setSelectedAssets] = useState('');   
  const [selectedDisposedDate, setSelectedDisposedDate] = useState('');   
  const [selectedDisposer, setSelectedDisposer] = useState('');   
  const [selectedAmountDisposed, setSelectedAmountDisposed] = useState('');   
  const [selectedCreatedBy, setselectedCreatedBy] = useState('');   
  const [selectedDisposed, setSelectedDisposed] = useState('');   

  
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
        setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  useEffect(() => {
    const retrieveAdminStatus = async () => {
      try {
        const adminStatus = await AsyncStorage.getItem('admin');
        setIsAdmin(adminStatus === 'true');
        // console.log(adminStatus); 
      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveAdminStatus();
  }, []);

  //fetch records
  const fetchAssetsDisposer = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/assetdisposed', { headers });

      // console.log(response);
      const results = response.data?.data?.asset_disposals;
      setTableData(results);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }else{
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllAssetsRegister = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/fixedassets', { headers });

      const results = response.data?.data;
      setTableData1(results);
      console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchContinents = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get('https://asset-api.patna.ng/api/fixedassets', { headers });

  
  //     const results = response.data?.data;
  //     setTableData1(results);
  //     console.log(results, "heeeer");
  //   } catch (error) {
  //     const errorStatus = error.response?.data?.message;
  //     console.log(errorStatus);
  //     setTableData1([]);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    if (bearer) {
      fetchAssetsDisposer();
      fetchAllAssetsRegister();
      // fetchContinents();
    }
  }, [bearer]);

  //create beneficiary
  const createAssetsDisposal = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/assetdisposed/create_assetdisposal',
        {
          asset_id: selectedAssets,
          date_disposed: selectedDisposedDate,
          amount_disposed: selectedAmountDisposed,
         
        },
        { headers }
      );
      console.log(response)
      fetchAssetsDisposer();
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

  const handleAssetsChange = (event) =>{
    setSelectedAssets(event.target.value)
  }
  // const handleAmountChange = (event) =>{
  //   setSelectedDisposedDate(event.target.value)
  // }
  // const handleDisposerDateChange = (event) =>{
  //   setSelectedAmountDisposed(event.target.value)
  // }
  // const handleCreatedByDateChange = (event) =>{
  //   setselectedCreatedBy(event.target.value)
  // }
  // const handleDisposerChange = (event) =>{
  //   setSelectedDisposer(event.target.value)
  // }

  // const handleContinentChange = (event) => {
  //   setSelectedContinent(event.target.value)
  // }
  
  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {

    const foundDisposal = tableData.find(item => item.id === id);


    const { selectedAssets, electedDisposedDate, selectedDisposed, electedCreatedBy, selectedDisposer } = foundDisposal;


    setSelectedAssets(selectedAssets || '');
    setSelectedDisposedDate(selectedDisposedDate || '');
    setSelectedDisposed(selectedDisposed || '');
    setselectedCreatedBy(selectedCreatedBy || '');
    setSelectedDisposer(selectedDisposer || '');
    
    setShow1(true);
    setEyeClicked(true);
  };


  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this asset disposal. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`https://promix-assets.promixaccounting.com/api/parishes/delete_parish?id=${id}`, { headers });
      fetchAssetsDisposer();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

  //update function
  const editUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        'https://payroll.patna.ng/api/admin/users/update',
        {
          name: fullName1,
          // id: deptId, 
          email: email1,
          phone: phone1,
          
        },
        { headers }
      );

      fetchAssetsDisposer();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      const errorStatus = error.response?.data?.message || 'An error occurred';

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData.filter(item => item.asset_id.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);




  return (
    <AssetsContinentDisposalUi
      // Pass necessary props from the logic to the UI component
      show={show}
      show1={show1}
      handleShow={handleShow}
      handleShow1={handleShow1}
      handleClose={handleClose}
      handleClose1={handleClose1}
      createAssetsDisposal={createAssetsDisposal}
      editUser={editUser}
      isLoading={isLoading}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      entriesPerPage={entriesPerPage}
      setEntriesPerPage={setEntriesPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalEntries={totalEntries}
      totalPages={totalPages}
      startIndexx={startIndexx}
      endIndexx={endIndexx}
      displayedData={displayedData}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleEyeClick={handleEyeClick}
      handleTrashClick={handleTrashClick}
      fullName={fullName}
      setFullName={setFullName}
      address={address}
      setAddress={setAddress}
      office_address={office_address}
      setOfficeAddress={setOfficeAddress}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      fullName1={fullName1}
      setFullName1={setFullName1}
      phone1={phone1}
      setPhone1={setPhone1}
      email1={email1}
      setEmail1={setEmail1}
      tableData={tableData}
      tableData1={tableData1}
      
      formatDate={formatDate}
      


      selectedAssets = {selectedAssets}
      selectedDisposedDate = {selectedDisposedDate}
      selectedAmountDisposed = {selectedAmountDisposed}
      selectedCreatedBy = {selectedCreatedBy}
      selectedDisposer = {selectedDisposer}
      isAdmin={isAdmin}
      setSelectedDisposedDate={setSelectedDisposedDate}
      setSelectedAmountDisposed = {setSelectedAmountDisposed}
      setSelectedAssets ={setSelectedAssets}
      handleAssetsChange = {handleAssetsChange}
      // handleAmountChange = {handleAmountChange}
      // handleDisposerDateChange = {handleDisposerDateChange}
      // handleCreatedByDateChange = {handleCreatedByDateChange}
      // handleDisposerChange = {handleDisposerChange}
    />
  )
}

export default AssetsContinentDisposal;
