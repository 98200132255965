import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../Pages/Nav/Nav.module.css';
import Logo from '../Images/patna.png';
import Avater from '../Images/avatar33.png';
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/plugins/metisMenu/metisMenu.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/typicons/src/typicons.min.css";
import "./assets/plugins/themify-icons/themify-icons.min.css";
import "./assets/plugins/datatables/dataTables.bootstrap4.min.css";
import "./style.css";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Spinner, Button } from 'react-bootstrap';

export const AdminHeaderNav = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bearer, setBearer] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isChecker, setIsChecker] = useState(false);
  const [name, setName] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mycompanyName, setMyCompanyName] = useState('This Company');
  useEffect(() => {

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    readData()

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const retrieveAdminStatus = async () => {
      try {
        const adminStatus = await AsyncStorage.getItem('admin');
        setIsAdmin(adminStatus === 'true');

        const checkerStatus = await AsyncStorage.getItem('checker');
        setIsChecker(checkerStatus === 'true');
        // console.log(checkerStatus, "ADMIN"); 
      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveAdminStatus();
  }, []);


  const hours = currentTime.getHours().toString().padStart(2, '0');
  const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  const seconds = currentTime.getSeconds().toString().padStart(2, '0');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('userEmail');
      const value3 = await AsyncStorage.getItem('userPhone');
      const thiscompanyName = await AsyncStorage.getItem('companyName');
      const isAdmin = await AsyncStorage.getItem('admin');
      // console.log(thiscompanyName);
      if (thiscompanyName !== null) {
        setMyCompanyName(thiscompanyName);
      }

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setName(value1);
      }
      if (value2 !== null) {
        setEmail(value2);
      }
      if (value3 !== null) {
        setPhone(value3);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/logout',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      await AsyncStorage.clear();
      console.log("async cleared");
      navigate('/login');

      // Swal.fire({
      //   icon: 'success',
      //   title: 'Success',
      //   text: response.data.message,
      // });

      // console.log(response.data);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : 'An error occurred while logging out';
      if (errorMessage.toLowerCase().includes('unauthenticated') || errorMessage.toLowerCase().includes('unauthorized')) {
        navigate('/login');
        return;
      }
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Failed',
      //   text: errorMessage,
      // });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/change-password',
        {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: confirmPassword
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      handleClose();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      const errorMessage = error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const truncateName = (name) => {

    const parts = name.split(' ');


    if (parts.length > 2) {

      return parts.slice(0, 2).join(' ');
    }


    return name;
  };



  const [isDesktopHidden, setDesktopHidden] = useState(false);
  const handleSidebarToggle = () => {
    setDesktopHidden((prev) => !prev);
  };


  return (
    <div className='fixed-top'>

      <div style={{ backgroundColor: "#F5F5F5", }} >
        <nav className="navbar-custom-menu navbar navbar-expand-lg m-0">

          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ marginTop: 20 }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Current Password"
                    // autoFocus
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <div style={{ marginTop: 10 }} />
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter New Password"
                    // autoFocus
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div style={{ marginTop: 10 }} />
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter New Password Again"
                    // autoFocus
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>






            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Go back
              </Button>
              <Button variant="success" onClick={handleChangePassword}>
                {isLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Updating your settings, Please wait...</span>
                  </>
                ) : (
                  "Change Password"
                )}
              </Button>
            </Modal.Footer>
          </Modal>


          <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div className='logoCont'>
              <NavLink to={'/admin'} className="navbar-brand" >
                <img src={Logo} className={classes.Logo} style={{ height: 25, width: 100 }} /></NavLink>
              {/* <p className={classes.companyName}>{mycompanyName}</p> */}
              <p> {mycompanyName} </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <li className="nav-item dropdown user-menu desktopShow mobileHiden" style={{ listStyle: 'none', }} >
                <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" style={{ paddingRight: '0', }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa-sharp fa-solid fa-user"></i>
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-header d-sm-none">
                    <NavLink to={'#'} href="#" className="header-arrow"><i className="icon ion-md-arrow-back"></i></NavLink>
                  </div>
                  <div className="user-header">
                    <div className="img-user">
                      <img src={Avater} alt="Avater" />
                    </div>

                    <h6>{truncateName(name)}</h6>
                    {/* <span>{email}</span> */}
                  </div>

                  {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-user-outline"></i> My Profile</NavLink> */}
                  {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-edit"></i> Edit Profile</NavLink>
                          <NavLink to={'#'} className="dropdown-item"><i className="typcn typcn-user-outline"></i> Manage User</NavLink> */}
                  {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-arrow-shuffle"></i> Activity Logs</NavLink> */}
                  <div style={{ cursor: "pointer" }} onClick={() => handleShow()} className="dropdown-item"><i className="typcn typcn-cog-outline"></i> Change Password</div>
                  <div style={{ cursor: "pointer" }} onClick={() => handleLogout()} className="dropdown-item"><i className="typcn typcn-key-outline"></i>Sign Out</div>
                </div>
              </li>
              <div className="nav-clock">
                <div className="time">
                  <span className="time-hours">{hours}</span>
                  <span className="time-min">{minutes}</span>
                  <span className="time-sec">{seconds}</span>
                </div>
              </div>
            </div>
          </div>

          <ul className={`desktopHiden ${isDesktopHidden ? 'hidden' : ''}`}>
            <li className="nav-item">
              <NavLink to={'/admin'} id="nav-color" className="nav-link active homeText" aria-current="page" href="admin"><i className="fas fa-home"></i> Home</NavLink>
            </li>

            <li className="nav-item dropdown">
              <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                <i className="fa-solid fa-gear"></i>  Admin</NavLink>
              <ul className="dropdown-menu">
                <li><NavLink to={"/manage_user"} className="dropdown-item">Manage User</NavLink></li>
                <li><NavLink to={"/manage_continent"} className="dropdown-item">Manage Continent</NavLink></li>
                <li><NavLink to={"/region"} className="dropdown-item">Manage Region</NavLink></li>
                <li><NavLink to={"/province"} className="dropdown-item">Manage Province</NavLink></li>
                {/* <li><NavLink to={"/zone"} className="dropdown-item">Manage Zone</NavLink></li>
        <li><NavLink to={"/area"} className="dropdown-item">Manage Area</NavLink></li>
        <li><NavLink to={"/parish"} className="dropdown-item">Manage Parish</NavLink></li> */}

              </ul>
            </li>

            <li className="nav-item dropdown">
              <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                <i className="fa-solid fa-gear"></i>  Configuration & Setup</NavLink>
              <ul className="dropdown-menu">
                <li><NavLink to={'/assets_category'} className="dropdown-item">Manage Assets Category</NavLink></li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa-solid fa-file-invoice-dollar"></i> Fixed Assets Register
              </NavLink>
              <ul className="dropdown-menu main-dropdown">
                {/* <li><NavLink to={'/create_fixed'} className="dropdown-item">Create Assets Register</NavLink></li>
      <li><NavLink to={'/create_fixed_asset'} className="dropdown-item">Upload Assets Register</NavLink></li> */}
                <li><NavLink to={'/assets_subcategoryDisp'} className="dropdown-item">Manage Assets Disposal</NavLink></li>

              </ul>




            </li>

            <li className="nav-item dropdown">
              <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa-solid fa-file-invoice-dollar"></i> Reports
              </NavLink>
              <ul className="dropdown-menu main-dropdown">
                {/* <li><NavLink to={'/create_fixed_asset'} className="dropdown-item">Fixed Assets Class </NavLink></li> */}
                <li><NavLink to={'/fixed_assets_class_summary'} className="dropdown-item">Fixed Assets Class Summary</NavLink></li>
                <li><NavLink to={'/fixed_asset_report'} className="dropdown-item">Fixed Asset Register</NavLink></li>
                <li><NavLink to={'/transfer_report'} className="dropdown-item">Transfer Report</NavLink></li>
                <li><NavLink to={'/province_compliance_report'} className="dropdown-item">Province Compliance Report</NavLink></li>
              </ul>
            </li>

            <li className="nav-item dropdown user-menu desktopShow" style={{ listStyle: 'none', }} >
              <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" style={{ paddingRight: '0', }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa-sharp fa-solid fa-user"></i>
              </NavLink>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header d-sm-none">
                  <NavLink to={'#'} href="#" className="header-arrow"><i className="icon ion-md-arrow-back"></i></NavLink>
                </div>
                <div className="user-header">
                  <div className="img-user">
                    <img src={Avater} alt="Avater" />
                  </div>

                  <h6>{truncateName(name)}</h6>
                  {/* <span>{email}</span> */}
                </div>

                {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-user-outline"></i> My Profile</NavLink>
                          <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-edit"></i> Edit Profile</NavLink>
                          <NavLink to={'#'} className="dropdown-item"><i className="typcn typcn-user-outline"></i> Manage User</NavLink> */}
                {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-arrow-shuffle"></i> Activity Logs</NavLink> */}
                <div style={{ cursor: "pointer" }} onClick={() => handleShow()} className="dropdown-item"><i className="typcn typcn-cog-outline"></i> Change Password</div>
                <div style={{ cursor: "pointer" }} onClick={() => handleLogout()} className="dropdown-item"><i className="typcn typcn-key-outline"></i>Sign Out</div>
              </div>
            </li>

          </ul>

          <div className="sidebar-toggle-icon" id="sidebarCollapse" onClick={handleSidebarToggle}>
            sidebar toggle<span></span>
          </div>
        </nav>



      </div>

      <div style={{ backgroundColor: "#F5F5F5", }} className={classes.topLogoTime}>
        <nav className="navbar-custom-menu navbar-menu navbar-expand-lg m-0">
          <div className="sidebar-toggle-icon" id="sidebarCollapse">
            sidebar toggle<span></span>
          </div>

          <div className="d-flex mainMenuStyle">

            <div className='mainMenu'>
              <ul className="navbar-nav flex-row align-items-center ">


                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav">


                    <li className="nav-item dropdown user-menu desktopHiden" style={{ listStyle: 'none', }} >
                      <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" style={{ paddingRight: '0', }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-sharp fa-solid fa-user"></i>
                      </NavLink>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-header d-sm-none">
                          <NavLink to={'#'} href="#" className="header-arrow"><i className="icon ion-md-arrow-back"></i></NavLink>
                        </div>
                        <div className="user-header">
                          <div className="img-user">
                            <img src={Avater} alt="Avater" />
                          </div>

                          <h6>{truncateName(name)}</h6>
                          {/* <span>{email}</span> */}
                        </div>

                        {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-user-outline"></i> My Profile</NavLink>
                            <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-edit"></i> Edit Profile</NavLink>
                            <NavLink to={'#'} className="dropdown-item"><i className="typcn typcn-user-outline"></i> Manage User</NavLink> */}
                        {/* <NavLink to={'#'} href="#" className="dropdown-item"><i className="typcn typcn-arrow-shuffle"></i> Activity Logs</NavLink> */}
                        <div style={{ cursor: "pointer" }} onClick={() => handleShow()} className="dropdown-item"><i className="typcn typcn-cog-outline"></i> Change Password</div>
                        <div style={{ cursor: "pointer" }} onClick={() => handleLogout()} className="dropdown-item"><i className="typcn typcn-key-outline"></i>Sign Out</div>
                      </div>
                    </li>

                    <li className="nav-item">
                      <NavLink to={'/admin'} id="nav-color" style={{ paddingLeft: '0', }} className="nav-link active" aria-current="page" href="admin"><i className="fas fa-home"></i> Home</NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                        <i className="fa-solid fa-gear"></i>  Admin</NavLink>
                      <ul className="dropdown-menu">
                        <li><NavLink to={"/manage_user"} className="dropdown-item">Manage User</NavLink></li>
                        <li><NavLink to={"/manage_deleted_user"} className="dropdown-item">Manage Deleted User</NavLink></li>
                        <li><NavLink to={"/manage_continent"} className="dropdown-item">Manage Continent</NavLink></li>
                        <li><NavLink to={"/region"} className="dropdown-item">Manage Region</NavLink></li>
                        <li><NavLink to={"/province"} className="dropdown-item">Manage Province</NavLink></li>
                        <li><NavLink to={"/zone"} className="dropdown-item">Manage Zone</NavLink></li>
                        <li><NavLink to={"/area"} className="dropdown-item">Manage Area</NavLink></li>
                        <li><NavLink to={"/parish"} className="dropdown-item">Manage Parish</NavLink></li>

                      </ul>
                    </li>


                    <li className="nav-item dropdown">
                      <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-gear"></i>  Configuration & Setup
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li><NavLink to={'/assets_category'} className="dropdown-item">Manage Assets Category</NavLink></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-file-invoice-dollar"></i> Fixed Assets Register
                      </NavLink>
                      <ul className="dropdown-menu main-dropdown">

                        <li><NavLink to={'/create_fixed'} className="dropdown-item">Create Assets Register</NavLink></li>
                        <li><NavLink to={'/create_fixed_asset'} className="dropdown-item">Upload Assets Register</NavLink></li>
                        <li><NavLink to={'/assets_subcategoryDisp'} className="dropdown-item">Manage Assets Disposal</NavLink></li>

                      </ul>




                    </li>


                    <li className="nav-item dropdown">
                      <NavLink to={'#'} id="nav-toggle" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-file-invoice-dollar"></i> Reports
                      </NavLink>
                      <ul className="dropdown-menu main-dropdown">
                        {/* <li><NavLink to={'/create_fixed_asset'} className="dropdown-item">Fixed Assets Class </NavLink></li> */}
                        {/* <li><NavLink to={'/fixed_assets_class_summary'} className="dropdown-item">Fixed Assets Class Summary</NavLink></li> */}
                        <li><NavLink to={'/fixed_asset_report'} className="dropdown-item">Fixed Asset Register</NavLink></li>
                        <li><NavLink to={'/transfer_report'} className="dropdown-item">Transfer Report</NavLink></li>
                        <li><NavLink to={'/province_compliance_report'} className="dropdown-item">Province Compliance Report</NavLink></li>

                      </ul>
                    </li>
                  </ul>
                </div>




              </ul>
            </div>


          </div>
        </nav>



      </div>
    </div>

  )
}