import React from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { RegionalActHeaderNav } from './RegionalActHeaderNav';
import { InfoFooter } from '../../InfoFooter';
import classes from './ParishAdmin.module.css';
import favicon from '../../Images/faviconn.png';

function ParishAdminUi2({
  show,
  show1,
  handleShow,
  handleShow1,
  handleClose,
  handleClose1,
  createParishes,
  churchAddress,
  setChurchAddress,
  churchAddress1,
  setChurchAddress1,
  fullName,
  email,
  phone,
  setFullName,
  setEmail,
  setPhone,
  isLoading,
  selectedContinent1,
      selectedRegion1,
      selectedProvince1,
      selectedZone1,
      selectedArea1,
  loading,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  totalPages,
  startIndexx,
  endIndexx,
  displayedData,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  
  fullName1,
  setFullName1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  // tableData,
description1,
setDescription1,
  tableData1,
  tableData2,
  tableData3,
  tableData4,
  tableData5,
  tableData6,
  tableData7,
  tableData8,
  
 
  // createZone,

  description,
  setDescription,
  // continentId,
  // setContinentId,
  selectedRegion,
  selectedProvince,
  selectedArea,
  handleZoneChange,
  handleRegionChange,
  handleProvinceChange,
  handleZoneChange1,
  handleRegionChange1,
  handleProvinceChange1,
  handleParisChange,
  handleAreaChange,
  handleAreaChange1,
  formatDate,
  editParish,
  handleContinentChange,
  handleContinentChange1,
  selectedContinent,
  selectedZone,
  setSelectedContinent,
  parishLoading
  

}) {
  // const formattedTotalEntries = totalEntries.toLocaleString();
  return (
    <div style={{ marginTop: '8rem' }}>
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <RegionalActHeaderNav />
            <div className='newBody'>
              <div className='newWidth'>

                {/* <!--Content Header (Page header)--> */}
                <div className="content-header row align-items-center m-0">

                  <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                    >
                      <Button variant="success" onClick={handleShow} >
                        Add New Parish
                      </Button>
                    </div>

                  </nav>
                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        <h1 className="font-weight-bold">Manage Parish</h1>
                        <small>Create and update your parish...</small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--/.Content Header (Page header)--> */}
                <div className="body-content">
                  <div className="row">

                    <div className="col-lg-12 col-xl-6">
                      <div className="row">

                        <div className="col-md-6 col-lg-6">

                          {/* <!--Feedback--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Balance indicator--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Time on site indicator--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Top Referrals--> */}

                        </div>
                        <div className="col-md-6 col-lg-6">

                          {/* <!--Sessions by device--> */}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                      <div className="card">


                      </div>
                    </div>

                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Parish</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          
                          {/* <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent}
                            onChange={handleContinentChange}
                            >
                              <option value="">Select Continent</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion}
                              onChange={handleRegionChange}
                            >
                              <option value="">Select Region</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince}
                              onChange={handleProvinceChange}
                            >
                            <option value="">Select Province</option>
                            {tableData5.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select> */}
                            <div style={{ marginTop: 10 }} />
                            {/* <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone}
                              onChange={handleZoneChange}
                            >
                            <option value="">Select Zone</option>
                            {tableData1.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select> */}
                            <div style={{ marginTop: 10 }} />
                            {/* <Form.Label>Area</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedArea}
                              onChange={handleAreaChange}
                            >
                              <option value="">Select Area</option>
                            {tableData7.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select> */}
                            
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Discription"
                              
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Church Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              
                              value={churchAddress}
                              onChange={(e) => setChurchAddress(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Checker Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Checker Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email"
                              
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Checker Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createParishes}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Creating Parish, Please wait...</span>
    </>
  ) : (
                "Create Parish"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="col-lg-12">
                      <div className="card">
                        {/* <div className="card-header">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 className="fs-17 font-weight-600 mb-0">Users List</h6>
                            </div>

                          </div>
                        </div> */}
                        <div className="card-body">
                          <div className="table-resposive">
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                              <div className={classes.greenbtn} style={{ display: 'flex', }}>
                                <div>
                                  <button>Copy</button>
                                  <button>Excel</button>
                                  <button>PDF</button>
                                  <button className={classes.diffbtn}>Column visibility</button>
                                </div>
                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="text-right modal-effect ">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div className="mr-2">Search:</div>
                                    <input
                                      type="search"
                                      value={searchTerm}
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="DataTables_Table_0"
                                      onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        // setCurrentPage(1);
                                      }}
                                    />
                                  </div>

                                </div>
                              </div>
                            </div>


                            {parishLoading ? (
                              <p>Fetching parishes...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead style={{ whiteSpace: 'nowrap' }}>
                                    <tr>
                                      <th>ID</th>
                                      {/* <th>Continent</th>
                                      <th>Region</th>
                                      <th>Province</th>
                                      <th>Zone</th>
                                      <th>Areas</th> */}
                                      <th>Parish</th>
                                      <th>Created At</th>
                                      <th>Updated At</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ whiteSpace: 'nowrap' }}>
                                    {tableData8.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index +1}</td>
                                        {/* <td>{item.continent?.description}</td>
                                        <td>{item.region?.description}</td>
                                        <td>{item.province?.description}</td>
                                        <td>{item.zone1?.description}</td>
                                        <td>{item.area1?.description}</td> */}
                                        <td>{item.description}</td>
                                        <td>{formatDate(item.created_at)}</td>
                                        <td>{formatDate(item.updated_at)}</td>
                                        <td>
                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                            <i className="far fa-edit"></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                            <i className="far fa-trash-alt"></i>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            <div className={classes.endded}>
                              <p>
                                Showing {`Page ${currentPage} to ${totalPages} pages`} of {totalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>

                            <Modal show={show1} onHide={handleClose1} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Parish</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          
                          {/* <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent1}
                            onChange={handleContinentChange1}
                            >
                              <option value="">Select Continent</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion1}
                              onChange={handleRegionChange1}
                            >
                              <option value="">Select Region</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince1}
                              onChange={handleProvinceChange1}
                            >
                            <option value="">Select Province</option>
                            {tableData5.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} /> */}
                            <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone1}
                              onChange={handleZoneChange1}
                            >
                            <option value="">Select Zone</option>
                            {tableData6.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Area</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedArea1}
                              onChange={handleAreaChange1}
                            >
                              <option value="">Select Area</option>
                            {tableData7.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                              </Form.Select>
                            
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Discription"
                              
                              value={description1}
                              onChange={(e) => setDescription1(e.target.value)}
                            />

                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Church Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              
                              value={churchAddress1}
                              onChange={(e) => setChurchAddress1(e.target.value)}
                            />

                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose1}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={editParish}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Updating Parish, Please wait...</span>
    </>
  ) : (
                "Update Parish"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        <InfoFooter />
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>
    </div>
  );
}

export default ParishAdminUi2;
