import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import ZoneUi from './ZoneAdminUi';
import { useNavigate } from 'react-router-dom';


function ZoneAdmin() {
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(new Date());
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [zoneLoad, setZoneLoad] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [rccgRegions, setRccgRegions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');

  const [description, setDescription] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  
  const [description1, setDescription1] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  // const [selectedProvince, setSelectedProvince] = useState("");


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
        setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  //fetch records
  const fetchZone = async () => {
    setZoneLoad(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/provinces/getazoneprovince', { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);
      // console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }else{ 
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
      }
    } finally {
      setZoneLoad(false);
    }
  };

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/continents', { headers });

      // console.log(response);
      const resultsss = response.data?.data;
      setTableData1(resultsss);
      // console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubContinents = async (selectedContinent) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubContinents1 = async (selectedContinent1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegions = response.data?.data;
    setRegions(subRegions);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setRegions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubRegions = async (selectedRegion) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(selectedRegion1, "here");
  const fetchSubRegions1 = async (selectedRegion1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegionss = response.data?.data;
      setRccgRegions(subRegionss);
      // console.log(subRegionss, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setRccgRegions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(selectedContinent1, selectedRegion1, "here");


  useEffect(() => {
    if (bearer) {
      fetchZone();
      fetchContinents();
    }
  }, [bearer]);

  //create beneficiary
  const createZone = async () => {
    setLoading(true);
    try {
      // console.log(description, selectedContinent, selectedProvince, selectedRegion, "hereeeee")
      const response = await axios.post(
        'https://asset-api.patna.ng/api/zones/create_by_admin',
        {
          description: description,
          // continent_id: selectedContinent,
          // province_id: selectedProvince,
          // region_id: selectedRegion,
        },
        { headers }
      );
      // console.log(response)
      fetchZone();
      handleClose();
      setDescription('');
      setSelectedContinent('');
      setSelectedProvince('');
      setSelectedRegion('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data.message);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedContinent1) {
      fetchSubContinents1(selectedContinent1);
    }
  }, [bearer, selectedContinent1]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegions(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer && selectedRegion1) {
      fetchSubRegions1(selectedRegion1);
    }
  }, [bearer, selectedRegion1]);


  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
  }


  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value)
  }

  const handleContinentChange1 = (event) =>{
    setSelectedContinent1(event.target.value)
    setSelectedRegion1("");
    setSelectedProvince1("");
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value)
  };

  
  const handleProvinceChange1 = (event) =>{
    setSelectedProvince1(event.target.value)
  }

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {
    // Find the selected grade from tableData using the id
    const foundZone= tableData.find(item => item.id === id);
    setSelectedZone(foundZone);
    setDescription1(foundZone?.description || '');
    // setSelectedContinent1(foundZone?.continent?.description || '');
    // setSelectedRegion1(foundZone?.region?.description || '');
    // setSelectedProvince1(foundZone?.province?.description || '');

    setShow1(true);
    setEyeClicked(true);
  };



  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this zone. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/zones/delete_zone?id=${id}`, { headers });
      fetchZone();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

  //update function
  const editZone = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/zones/update_zone',
        {
          description: description1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          region_id: selectedRegion1,
          id : selectedZone.id
          
        },
        { headers }
      );
handleClose1();
      fetchZone();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);




  return (
    <ZoneUi
      // Pass necessary props from the logic to the UI component
      show={show}
      show1={show1}
      handleShow={handleShow}
      handleShow1={handleShow1}
      handleClose={handleClose}
      handleClose1={handleClose1}
      // createUser={createUser}
      editZone={editZone}
      isLoading={isLoading}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      entriesPerPage={entriesPerPage}
      setEntriesPerPage={setEntriesPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalEntries={totalEntries}
      totalPages={totalPages}
      startIndexx={startIndexx}
      endIndexx={endIndexx}
      displayedData={displayedData}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleEyeClick={handleEyeClick}
      handleTrashClick={handleTrashClick}
      fullName={fullName}
      setFullName={setFullName}
      address={address}
      setAddress={setAddress}
      office_address={office_address}
      setOfficeAddress={setOfficeAddress}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      fullName1={fullName1}
      setFullName1={setFullName1}
      phone1={phone1}
      setPhone1={setPhone1}
      email1={email1}
      setEmail1={setEmail1}
      tableData={tableData}
      tableData4={tableData4}

      createZone = {createZone}
      description = {description}
      setDescription = {setDescription}
      // selected  = {selected }
      selectedContinent = {selectedContinent}
      setSelectedContinent = {setSelectedContinent}
      selectedRegion = {selectedRegion}
      setSelectedRegion = {setSelectedRegion}
      selectedProvince = {selectedProvince}
      setSelectedProvince = {setSelectedProvince}
      
      handleRegionChange = {handleRegionChange}
      handleProvinceChange = {handleProvinceChange}
      
      selectedContinent1 = {selectedContinent1}
      setSelectedRegion1 = {setSelectedRegion1}
      selectedRegion1 = {selectedRegion1}
      selectedProvince1 = {selectedProvince1}
      handleContinentChange = {handleContinentChange}
      handleContinentChange1 = {handleContinentChange1}
      handleRegionChange1 = {handleRegionChange1}
      handleProvinceChange1 = {handleProvinceChange1}
      setDescription1={setDescription1}
      description1={description1}
      zoneLoad={zoneLoad}

      formatDate = {formatDate}
      tableData1={tableData1}
      tableData2={tableData2}
      tableData3={tableData3}
      tableData5={tableData5}
      rccgRegions={rccgRegions}
      regions={regions}
    />
  )
}

export default ZoneAdmin;