import React, { useState, useEffect } from 'react';
import Happiness from '../../src/Images/happiness.svg';
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/plugins/metisMenu/metisMenu.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/typicons/src/typicons.min.css";
import "./assets/plugins/themify-icons/themify-icons.min.css";
import happiness from "./assets/dist/img/happiness.svg";
import "./assets/plugins/datatables/dataTables.bootstrap4.min.css";
import "./style.css";
import { AdminHeaderNav } from './AdminHeaderNav';
import { AdminHeadernavMainMenu } from './AdminHeadernavMainMenu';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../Pages/Footer/Footer';
import { InfoFooter } from '../InfoFooter';
import { Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import classes from './Admin.module.css';
import favicon from '../Images/faviconn.png'
import PieChart1 from '../Images/Pie-Chart-1.png'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import CardIcon from '../../src/Images/icon1.svg'
import CardIcon1 from '../../src/Images/icon2.svg'
import CardIcon2 from '../../src/Images/icon3.svg'






function Admin() {
  const handleShow1 = () => setShow1(true);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');
  const navigate = useNavigate();
  const [displayedItemsFired, setDisplayedItemsFired] = useState(false);
  const [outstanding, setOutstanding] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  
  const [show1, setShow1] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [user, setUser] = useState(null);
  const [assetLoading, setAssetLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [selectedFiles1, setSelectedFiles1] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [loaddd, setLoaddd] = useState(false);
  const [show2, setShow2] = useState(false);
  const [tableData9, setTableData9] = useState([]);
  const [totalAsset, setTotalAsset] = useState("");
  const [totalUser, setTotalUser] = useState("");
  const [totalValue, setTotalValue] = useState("");


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }



  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('tobi');
      const details = await AsyncStorage.getItem('userToken');

      if (detail !== null) {
        setUser(detail);
      }


      if (details !== null) {
        setBearer(details);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const fetchStat = async () => {
    setAssetLoading(true);
    try {
      const response = await axios.get('https://asset-api.patna.ng/api/fixedassets/statistics', { headers });
      const results = response.data?.data;
      setTableData(results?.parishAssets);
      setTotalAsset(results?.totalAssets);
      setTotalUser(results?.totalUsers);
      setTotalValue(results?.totalValue);
      // console.log(results, "here mi dey");
    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData([]);
      }
    } finally {
      setAssetLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchStat();

    }
  }, [bearer]);


  const handleViewFixed = async (id) => {
    setSelectedItemId(id);
        setViewLoading(true);
        try {
          const response = await axios.get(`https://asset-api.patna.ng/api/fixedassets/get-assets-by-parish?parish_id=${id}`,
            
            {headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`
        }});

          const instructionData1 = response.data?.data;
          console.log(instructionData1, "beforess");
          setResponseData(instructionData1);
          navigate('/view_parish_fixed_register', { state: { responseData1: instructionData1 } });
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
        }

        } finally {
          setViewLoading(false);
        }
      }

  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const formattedTotalAsset = totalAsset ? totalAsset.toLocaleString() : '0';
  const numberValue = parseFloat(totalValue);
  const formattedTotalValue = numberValue ? numberValue.toLocaleString() : '0';
  const formattedTotalUser = totalUser ? totalUser.toLocaleString() : '0';
  return (

    // <div className="fixed">
    <div style={{ marginTop: '8rem', }}>
      {/* <!-- #END# Page Loader --> */}
      <div></div>
      <div className="wrapper">

        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <AdminHeaderNav />


            {/* <!--Content Header (Page header)--> */}
            <div className='newBody'>
              <div className='newWidth'>
                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        <h1 className="font-weight-bold"> Welcome, {user} </h1>
                        <small>From now on you will start your activities.</small>
                      </div>
                     
                    </div>
                  </div>
                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                      <div className="media-body" style={{ marginTop: 20 }}>
                        <h1 className="font-weight-bold"> Fixed Asset Register </h1>

                      </div>
                     
                    </div>
                  </div>
                </div>

                <div className="body-content">

                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className="card"> */}
                        {/* <div className="card-body"> */}
                          {/* <div className="card-body"> */}


                            <div className="row" style={{ marginTop: 20, marginBottom: 30 }}>
                              <div className="col-md-4">
                                <div className={classes.subGridDetails}>
                                  <div className={classes.iconCont}>
                                    <img src={CardIcon} alt='Icon' className={classes.img} />
                                  </div>
                                  <small>Total Assets Created</small>
                                  <h1 className={classes.statText}>{assetLoading ? <Spinner animation="border" size="sm" /> : formattedTotalAsset}</h1>
  
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className={classes.subGridDetails1}>
                                  <div className={classes.iconCont}>
                                    <img src={CardIcon1} alt='Icon' className={classes.img} />
                                  </div>
                                  <small>Total Assets Value</small>
                                  <h1 className={classes.statText}>{assetLoading ? <Spinner animation="border" size="sm" /> : formattedTotalValue}</h1>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className={classes.subGridDetails2}>
                                  <div className={classes.iconCont}>
                                    <img src={CardIcon2} alt='Icon' className={classes.img} />
                                  </div>
                                  <small>Total Number of Users</small>
                                  <h1 className={classes.statText}>{assetLoading ? <Spinner animation="border" size="sm" /> : formattedTotalUser}</h1>

                                </div>
                              </div>
                            </div>






                          {/* </div> */}
                        {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>



                  <div className="row">

                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-resposive">
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                              <div className={classes.greenbtn} style={{ display: 'flex', }}>

                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className={classes.showentries} style={{ display: 'flex', }}>

                                <div>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>

                              <div className="mobile-viewsearch " style={{ display: 'none !important' }}>
                                <div id="" className="">
                                  <div className="d-flex justify-content-start align-items-center" style={{ display: 'none !important' }}>
                                    <div className="mr-2">Search:</div>
                                    <input
                                      type="search"
                                      value={searchTerm}
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="DataTables_Table_0"
                                      onChange={(e) => {
                                        setSearchTerm(e.target.value);

                                      }}
                                    />
                                  </div>

                                </div>
                              </div>
                            </div>


                          {assetLoading ? (
                              <p>Fetching register...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead >
                                    <tr>
                                      <th>S/N</th>
                                      <th>Parishes</th>
                                      <th>Number of Assets</th>
                                      <th>Number of Approved Asset</th>
                                      <th>Total Approved Value</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                    
                                      {displayedData.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item.registered}</td>
                                          <td>{item.approved}</td>
                                          <td style={{textAlign: "right"}}>
                  {parseFloat(item.value).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                                        </td>
                                          <td style={{ whiteSpace: 'nowrap' }}>
                                          <div className="btn btn-success-soft btn-sm mr-1" style={{ marginLeft: 5 }} onClick={() => handleViewFixed(item.id)}>
    <label htmlFor="fileInput" style={{ cursor: 'pointer' }} >
      {viewLoading && selectedItemId === item.id ? (
        <Spinner size='sm'/>
      ) : (
        "View Fixed Asset"
      )}
    </label>
  </div>

                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            )} 

                         

                           

                            <div className={classes.endded}>
                              <p>
                                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h6 className="fs-17 font-weight-600 mb-0">All Employees</h6>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className={classes.belowheader}>
                                                    <div className={classes.greenbtn}>
                                                        <div>
                                                            <button>Copy</button>
                                                            <button>Excel</button>
                                                            <button>PDF</button>
                                                            <button className={classes.diffbtn}>Column visibility</button>
                                                        </div>
                                                    </div>
                                                    <div className={classes.searchside}>
                                                        <label className={classes.onlylabel}>Search:</label>
                                                        <input className={classes.thesearch} type="search" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>



              </div>

            </div>

            {/* <!--/.body content--> */}
          </div>
          <InfoFooter />
          {/* <div className="overlay"></div> */}
        </div>
      </div>



    </div>

  );
}

export default Admin;