import React, { useState, useEffect } from 'react';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import { HomeAdminHeader } from '../HomeAdminHeader';
// import Footer from '../../Pages/Footer/Footer';
import { InfoFooter } from '../../InfoFooter';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import favicon from '../../Images/faviconn.png'
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

function EditUserProvince() {
  const location = useLocation();
  const selectedUser = location.state;
    const [email, setEmail] = useState(selectedUser?.email || " ");
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [name, setName] = useState(selectedUser?.name || " ");
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedParish, setSelectedParish] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [phoneNo, setPhoneNo] = useState(selectedUser?.phone_number || " ");
    const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [tableData9, setTableData9] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

 

  useEffect(() => {
    const retrieveAdminStatus = async () => {
      try {
        const adminStatus = await AsyncStorage.getItem('admin');
        setIsAdmin(adminStatus === 'true');
        // console.log(adminStatus); 
      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveAdminStatus();
  }, []);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    // Reset other select inputs when role changes
    setSelectedContinent('');
    setSelectedRegion('');
    setSelectedProvince('');
    setSelectedZone('');
    setSelectedArea('');
    setSelectedParish('');
}


    
    const handleContinentChange = async (event) => {
      setSelectedContinent(event.target.value);
     setSelectedRegion("");
     setSelectedProvince("");
     setSelectedZone("");
     setSelectedArea("");
     setSelectedParish("");
    }
    const handleRegionChange = (event) => {
      setSelectedRegion(event.target.value);
     setSelectedProvince("");
    
    }
    const handleProvinceChange = (event) => {
      setSelectedProvince(event.target.value);
     setSelectedZone("");
    }
    const handleZoneChange = (event) =>{
      setSelectedZone(event.target.value);
     setSelectedArea("");
      
    }
    const handleParishChange = (event) =>{
      setSelectedParish(event.target.value);
      
    }
    const handleAreaChange = (event) =>{
      setSelectedArea(event.target.value);
     setSelectedParish("");
    }

    const createUser = async () => {
      setCreateLoading(true);
      try {
        const response = await axios.post(
          'https://promix-assets.promixaccounting.com/api/user/create',
          {
            name: name,
            email: email,
            role_id: selectedRole,
            phone_number: phoneNo,
            parish_id: selectedParish,
            continent_id: selectedContinent,
            region_id: selectedRegion,
            province_id: selectedProvince,
            zone_id: selectedZone,
            area_id: selectedArea,


            // church_address:churchAddress
           
          },
          { headers }
        );
     setPhoneNo('');
     setEmail('');
     setName('');
     setSelectedParish('');
     setSelectedProvince('');
     setSelectedContinent('');
     setSelectedArea('');
     setSelectedRegion('');
     setSelectedRole('');
        
       navigate('/manage_user');
        // return
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
        console.log(response.data);
  
      } catch (error) {
        const errorStatus = error.response?.data?.message;
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorStatus,
        });
        console.log(error);
      } finally {
        setCreateLoading(false);
      }
    };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

    
      const fetchContinents = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://promix-assets.promixaccounting.com/api/continents', { headers });
    
          // console.log(response);
          const Cont = response.data?.data;
          setTableData1(Cont);
          // console.log(results, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData1([]);
        } finally {
          setIsLoading(false);
        }
      };

          
      const fetchSubContinents = async (selectedContinent) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://promix-assets.promixaccounting.com/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subCont = response.data?.data;
          setTableData4(subCont);
          // console.log(subCont, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData4([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      
    
      const fetchSubRegions = async (selectedRegion) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://promix-assets.promixaccounting.com/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subReg = response.data?.data;
          setTableData5(subReg);
          // console.log(subCont, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData5([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      
      const fetchSubProvinces = async (selectedProvince) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://promix-assets.promixaccounting.com/api/provinces/getzonesprovince?province_id=${selectedProvince}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subReg = response.data?.data;
          setTableData6(subReg);
          // console.log(subCont, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData6([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      
    
      const fetchSubZones = async (selectedZone) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://promix-assets.promixaccounting.com/api/zones/getareaszone?zone_id=${selectedZone}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subReg = response.data?.data;
          setTableData7(subReg);
          // console.log(subCont, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData7([]);
        } finally {
          setIsLoading(false);
        }
      };

      const fetchSubAreas = async (selectedArea) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`https://promix-assets.promixaccounting.com/api/areas/getparishesarea?area_id=${selectedArea}`, 
          { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
        }, });
    
          // console.log(response);
          const subAreaa = response.data?.data;
          setTableData9(subAreaa);
          // console.log(subCont, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData9([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      
    
      useEffect(() => {
        if (bearer && selectedContinent) {
          fetchSubContinents(selectedContinent);
        }
      }, [bearer, selectedContinent]);

      useEffect(() => {
        if (bearer && selectedArea) {
          fetchSubAreas(selectedArea);
        }
      }, [bearer, selectedArea]);
    
      useEffect(() => {
        if (bearer && selectedRegion) {
          fetchSubRegions(selectedRegion);
        }
      }, [bearer, selectedRegion]);
    
      useEffect(() => {
        if (bearer && selectedProvince) {
          fetchSubProvinces(selectedProvince);
        }
      }, [bearer, selectedProvince]);
    
      useEffect(() => {
        if (bearer && selectedZone) {
          fetchSubZones(selectedZone);
        }
      }, [bearer, selectedZone]);


      const fetchUserType = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://promix-assets.promixaccounting.com/api/user/getusertype', { headers });
    
          // console.log(response);
          const Utype = response.data?.data;
          setTableData3(Utype);
          // console.log(results, "heeeer");
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData3([]);
        } finally {
          setIsLoading(false);
        }
      };
    
    
     
    
      useEffect(() => {
        if (bearer) {
            fetchContinents();
            fetchUserType();
        }
      }, [bearer]);
    

      const editUser = async () => {
        setCreateLoading(true);
        //  console.log(selectedUser.name, selectedUser.email, selectedUser.phone_number, "before");
              try {
          const response = await axios.post(
            'https://asset-api.patna.ng/api/user/update',
            {
              name: name,
              id: selectedUser.id, 
              email: email,
              phone_number: phoneNo,
            },
            { headers }
          );
          // console.log(fullName1, email1, phone1, "after");
          // fetchBeneficiaries();
    navigate('/manage_user_province')
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
    
          // console.log(response.data);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
              if (error.response && error.response.data && error.response.data.message) {
                  if (typeof error.response.data.message === 'string') {
                      errorMessage = error.response.data.message;
                  } else if (Array.isArray(error.response.data.message)) {
                      errorMessage = error.response.data.message.join('; ');
                  } else if (typeof error.response.data.message === 'object') {
                      errorMessage = JSON.stringify(error.response.data.message);
                  }

              }
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorMessage,
              });
        } finally {
          setCreateLoading(false);
        }
      };
  
  


    
      
      







  return (
    <div style={{ marginTop: '8rem', }}>
      <HomeAdminHeader />
      <div className='newBody'>
        <div className='newWidth'>
          <div className="wrapper">
            {/* <!-- Sidebar  --> */}


            {/* <!-- Page Content  --> */}
            <div className="content-wrapper">



              <div className="main-content">


                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-12 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} style={{ height: 30, width: 30 }} alt="favicon" /></i></div>
                      <div className="media-body" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                          <h1 className="font-weight-bold">Edit User </h1>
                          <small>Complete the respective fields ....</small>
                        </div>
                        <div style={{ marginBottom: 30 }}>
                          <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="body-content">



                <div className="col-lg-12">
                  <div className="card">
                    <div className="create-new-staff-card-header">
                      <div className="d-flex justify-content-between align-items-center">

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-body">


                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Name</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={name} onChange={(e) => setName(e.target.value)} name="identification" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Email</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="particulars" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Phone</label>
                                    <div className="col-sm-9">
                                    <input className="form-control" required="" type="number" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} name="identification" />
                                    </div>
                                  </div>
                                </div>
{isAdmin && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400" >Choose Role</label>
                                    <div className="col-sm-9">
                                    <Form.Select
                                        className="form-control"
                                        value={selectedRole}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>Choose Role</option>
                                        {tableData3.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.description}
                                        </option>
                                        ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
)}
                                {selectedRole !== '' && selectedRole !== '1' && (
        <>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400" >Continent</label>
                                    <div className="col-sm-9">
                                    <Form.Select
                                        className="form-control"
                                        value={selectedContinent}
                                        onChange={handleContinentChange}
                                        // disabled={selectedRole === "1"}
                                    >
                                        <option value="" disabled>Select Continent</option>
                                        {tableData1.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.description}
                                        </option>
                                        ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
                              
                                {selectedRole >= "3" && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region</label>
                                    <div className="col-sm-9">
                                    <Form.Select
                                        className="form-control"
                                        value={selectedRegion}
                                        onChange={handleRegionChange}
                                    >
                                        <option value="" disabled>Select Region</option>
                                        {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
)}
{selectedRole >= "4" && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province</label>
                                    <div className="col-sm-9">
                                    <Form.Select name="customer" className="form-control" required="" value={selectedProvince} onChange={handleProvinceChange}>
                                        <option value="">Choose Province</option>
                                        {tableData5.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
)}
 {selectedRole >= "5" && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Zone</label>
                                    <div className="col-sm-9">
                                    <Form.Select name="customer" className="form-control" required="" value={selectedZone} onChange={handleZoneChange}>
                                        <option value="">Choose Zone</option>
                                        {tableData6.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
 )}
{selectedRole >= "6" && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Area</label>
                                    <div className="col-sm-9">
                                    <Form.Select name="customer" className="form-control" required="" value={selectedArea} onChange={handleAreaChange}>
                                        <option value="">Choose Area</option>
                                        {tableData7.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
)}
{selectedRole >= "7" && (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Parish</label>
                                    <div className="col-sm-9">
                                    <Form.Select name="customer" className="form-control" required="" value={selectedParish} onChange={handleParishChange}>
                                        <option value="">Choose Parish</option>
                                        {tableData9.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                                    </Form.Select>
                                    </div>
                                  </div>
                                </div>
)}
</>
      )}
                              </div>



                              <div class="modal-footer">
                            <Button variant="success" onClick={editUser}  style={{borderRadius: 0 }} >
                            {createLoading ? (
                            <>
                            <Spinner  size='sm' /> 
                            <span style={{ marginLeft: '5px', }}>Updating user, Please wait...</span>
            </>
        ) : (
                        "Save Changes"
                            )}
                            </Button>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <InfoFooter />
    </div>
  )
}

export default EditUserProvince;