import React from 'react';
import './NotFound.css'; 
import NotFoundImg from '../../src/Images/error.svg';
import { Button } from 'react-bootstrap';
import {useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
      }

    return (
        <div className="route-not-found-page">
            <img src={NotFoundImg} alt="Route Not Found" className="not-found-image" />
            <p className="not-found-text">Route forbidden for this user</p>
            <div style={{ marginBottom: 30 }}>
        <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
      </div>
        </div>
        
    );
};

export default NotFound;
