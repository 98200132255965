import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';
import classes from './TrfReports.module.css'

export default function TrfReports() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [groupedData, setGroupedData] = useState([]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
        return formattedDate;
      }
    
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `Date Time: ${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { tableData } = location.state || {};

    const tableDataArray = Object.keys(tableData).map((key) => tableData[key]);


    return (
        <div className={classes.reportBody}>
            <div style={{ marginTop: '2rem' }} />

            <div className={classes.a42}>
                <div className={classes.header}>
                    <p style={{ width: '100%' }}>{company}</p>
                    {/* <h4>Account Activity Period, 01 - 01 (September - September) 2017/2018</h4> */}
                    <h4>Asset Transfer Report </h4>
                </div>
                <p>{currentDateTime}</p>

               
                   

                        <table className={classes.table2}>
                            <thead>
                            <th>S/N</th>
                                      <th>Asset Name</th>
                                      <th>From</th>
                                      <th>To</th>
                                      <th>Transferred By</th>
                                      <th>Date Transferred</th>
                            </thead>
                           
                           
                            <tbody>
                {tableDataArray.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                                        <td>{item.asset?.description}</td>
                                        <td>{item.parish}</td>
                                        <td>{item.toparish?.description}</td>
                                        <td>{item.transfer?.name}</td>
                                        <td style={{textAlign: "left"}}>{formatDate(item.created_at)}</td>
                    </tr>
                ))}
            </tbody>


                            
                        </table>
            </div>
        </div>
    );
}
