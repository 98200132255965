import React, { useState, useEffect } from 'react';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import { AdminHeaderNav } from '../AdminHeaderNav';
// import Footer from '../../Pages/Footer/Footer';
import { InfoFooter } from '../../InfoFooter';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import favicon from '../../Images/faviconn.png'
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

function CreateFixedAsset() {
  const [remarks, setRemarks] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
    const [assetName, setAssetName] = useState('');
    const [lifeTime, setLifeTime] = useState('');
    const [proceed, setProceed] = useState('');
    const [residualValue, setResidualValue] = useState('');
    const [address, setAddress] = useState('');
    const [subCat, setSubcat] = useState([]);
    const [subCat2, setSubcat2] = useState([]);
    const [categories, setCategories] = useState([]);
    const [amountPurchased, setAmountPurchased] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDate1, setSelectedDate1] = useState('');
    const [identificationNo, setIdentificationNo] = useState('');
    const [modelNo, setModelNo] = useState('');
    const [qty, setQty] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [serialNo, setSerialNo] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

    const handleDateChange1 = (event) => {
      setSelectedDate1(event.target.value);
    };
   
    
    const handleFileChange = (event) => {
      const files = event.target.files;
      setSelectedFiles(Array.from(files));
    };
    

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

   

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

    
    


  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        'https://asset-api.patna.ng/api/assetcategories',
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

      const fetchCategories = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://asset-api.patna.ng/api/assetcategories', { headers });
    
          // console.log(response);
          const cat = response.data?.data;
          setCategories(cat);
          // console.log(results);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          setCategories([]);
        } finally {
          setIsLoading(false);
        }
      };
    
      const fetchAssets = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://asset-api.patna.ng/api/fixedassets', { headers });
          const results = response.data?.data;
          // setTableData(results);
          // console.log(results);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
          // setTableData([]);
        } finally {
          setIsLoading(false);
        }
      };
    
    
      useEffect(() => {
        if (bearer) {
          fetchCategories();
          fetchAssets();
    
        }
      }, [bearer]);
    

  
  useEffect(() => {
    if (bearer) {
      fetchSubCat();
    }
  }, [bearer]);


      const createFixedAsset = async () => {
        setLoad(true);
      
        try {
          const formData = new FormData();
          formData.append('description', assetName);
          formData.append('category_id', selectedCategory);
          formData.append('amount_purchased', amountPurchased);
          formData.append('date_purchased', selectedDate);
          formData.append('location', address);
          formData.append('lifetime_in_years', lifeTime);
          selectedFiles.forEach((file, index) => {
            formData.append(`asset_document[${index}]`, file);
          });
          formData.append('identification_number', identificationNo);
          formData.append('proceed_on_sale', proceed);
          formData.append('date_disposed', selectedDate1);
          formData.append('residual_value', residualValue);
          formData.append('remarks', remarks);
          formData.append('model_number', modelNo);
          formData.append('quantity', qty);
          formData.append('unit_price', unitPrice);
          formData.append('serial_number', serialNo);
      
          const response = await axios.post(
            'https://asset-api.patna.ng/api/fixedassets/create_fixed_asset',
            formData,
            {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
      
          console.log(response.data.message);
          navigate('/admin');
         
          setIdentificationNo('');
          setAssetName('');
          setSelectedCategory('');
          setAmountPurchased('');
          setProceed('');
          setSelectedDate('');
          setSelectedDate1('');
          setLifeTime('');
          setAddress('');
          setResidualValue('');
         setModelNo('');
          setQty('');
          setUnitPrice('');
          setSerialNo('');
          setSelectedFiles([]);
          fetchAssets();
    
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
      
          console.log(response.data);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: errorMessage,
          });
          console.log(error);
        } finally {
          setLoad(false);
        }
      };

      
      const isButtonDisabled = !remarks || !assetName || !proceed  || !address || !amountPurchased ;
  

  const handleValueChange = (value, name, values) => {
    setAmountPurchased(value); // Update the balance state

  };

  const handleValueChange1 = (value, name, values) => {
    setUnitPrice(value); // Update the balance state

  };




  return (
    <div style={{ marginTop: '8rem', }}>
      <AdminHeaderNav />
      <div className='newBody'>
        <div className='newWidth'>
          <div className="wrapper">
            {/* <!-- Sidebar  --> */}


            {/* <!-- Page Content  --> */}
            <div className="content-wrapper">



              <div className="main-content">


                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} style={{ height: 30, width: 30 }} alt="favicon" /></i></div>
                      <div className="media-body" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                          <h1 className="font-weight-bold">Create New Fixed Asset Register </h1>
                          <small>Complete the respective fields ....</small>
                        </div>
                        <div style={{ marginBottom: 30 }}>
                          <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="body-content">



                <div className="col-lg-12">
                  <div className="card">
                    <div className="create-new-staff-card-header">
                      <div className="d-flex justify-content-between align-items-center">

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-body">


                              <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Choose Category</label>
                                    <div className="col-sm-9">
                                      <Form.Select name="customer" className="form-control" required="" value={selectedCategory} onChange={handleCategoryChange}>
                                        <option value="">Choose Category</option>
                                        {categories.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.description}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Identification Number / C of O / Reg. No.</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={identificationNo} onChange={(e) => setIdentificationNo(e.target.value)} name="identification" placeholder='e.g. Identification Number or C of O or Reg. No.' />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Asset Name</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={assetName} onChange={(e) => setAssetName(e.target.value)} name="particulars" />
                                    </div>
                                  </div>
                                </div>
                                
                                {/* <div className="col-md-6">
                                                                    <div className="form-group row">
                                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Choose Sub-Category</label>
                                                                        <div className="col-sm-9">
                                                                            <Form.Select name="customer" className="form-control" required="" value={selectedSubCategory} onChange={handleSubCatChange}>
                                                                                <option value="">Choose Sub-Category</option>
                                                                                {subCat.map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                  {item.gl_name}
                                                                                </option>
                                                                              ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400" >Amount Purchased</label>
                                    <div className="col-sm-9">
                                      {/* <input
                                        className="form-control"
                                        // placeholder='Total charge for the event'
                                        required=""
                                        type="text"
                                        value={amountPurchased}
                                        onChange={handleChange}
                                        name="amount-purchased"
                                        style={{ textAlign: "right" }}
                                      /> */}

                                      <CurrencyInput
                                        id="exampleForm.ControlInput1"
                                        name="Balance"
                                        // placeholder="Enter Opening balance"
                                        decimalsLimit={2}
                                        value={amountPurchased} // Set the value to the balance state
                                        onValueChange={handleValueChange}
                                        style={{

                                          minWidth: "100%",
                                          height: "calc(1.8em + .75rem + 2px)",
                                          border: '1px solid #e4e5e7',
                                          borderRadius: 5,
                                          overflow: 'hidden',
                                          zIndex: 999,
                                          fontSize: 14,
                                          padding: ".375rem .75rem",
                                          textAlign: "right"
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400" >Proceed on Sale</label>
                                    <div className="col-sm-9">
                                    <CurrencyInput
  id="exampleForm.ControlInput1"
  name="proceed"
  decimalsLimit={2}
  value={proceed} 
      onValueChange={setProceed}
  style={{
    
    minWidth: "100%",
    height: "calc(1.8em + .75rem + 2px)",
    border: '1px solid #e4e5e7',
    borderRadius: 5,
    overflow: 'hidden',
    zIndex: 999,
    fontSize: 14,
    padding: ".375rem .75rem",
    textAlign: "right"
  }}
                                      />
                                    </div>
                                  </div>
                                </div> */}


                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Date
                                      Purchased</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} />
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Date
                                      Disposed</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="date" onChange={handleDateChange1} name="date" value={selectedDate1} />
                                    </div>
                                  </div>
                                </div> */}

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Lifetime in Years</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={lifeTime} onChange={(e) => setLifeTime(e.target.value)} name="particulars" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Model/Type</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={modelNo} onChange={(e) => setModelNo(e.target.value)} name="model" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Serial Number Survey No. / Chasis No.
{selectedCategory === "6" && " (Chasis No.)"}</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={serialNo} placeholder='e.g. Serial Number or Survey No. or Chasis No.' onChange={(e) => setSerialNo(e.target.value)} name="serial" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Quantity</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="number" value={qty} onChange={(e) => setQty(e.target.value)} name="qty" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Unit Price</label>
                                    <div className="col-sm-9">
                                    <CurrencyInput
                                        id="exampleForm.ControlInput1"
                                        name="unit-price"
                                        // placeholder="Enter Opening balance"
                                        decimalsLimit={2}
                                        value={unitPrice} // Set the value to the balance state
                                        onValueChange={handleValueChange1}
                                        style={{

                                          minWidth: "100%",
                                          height: "calc(1.8em + .75rem + 2px)",
                                          border: '1px solid #e4e5e7',
                                          borderRadius: 5,
                                          overflow: 'hidden',
                                          zIndex: 999,
                                          fontSize: 14,
                                          padding: ".375rem .75rem",
                                          textAlign: "right"
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Location</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="particulars" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Residual Value</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" value={residualValue} onChange={(e) => setResidualValue(e.target.value)} name="particulars" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
  <div className="form-group row">
    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Supporting Documents</label>
    <div className="col-sm-9">
      <input
        className="form-control"
        required=""
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        onChange={handleFileChange}
        name="documents"
        multiple  
      />
    </div>
  </div>
</div>


                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Remarks</label>
                                    <div className="col-sm-9">
                                      <textarea
                                        className="form-control"
                                        required=""
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                        name="description"
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>



                                                            <div class="modal-footer">
                                                            <Button  variant="success" onClick={createFixedAsset} style={{borderRadius: 0 }}>
                    {load ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px', }}>Creating your register, Please wait...</span>
    </>
  ) : (
                "Create Fixed Asset Register"
                      )}
                    </Button>
                                                                {/* <Button>Save Changes</Button> */}
                                                                {/* <button type="submit" class="btn btn-success"><span id="loaderg" className="spinner-border spinner-border-sm me-2" role="status" style={{display:"none",}}></span>Save changes</button> */}
                                                            </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <InfoFooter />
    </div>
  )
}

export default CreateFixedAsset