import React, { useState, useEffect} from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import ManageDeletedUserUi from './ManageDeletedUserUi';
import Parish from '../Manage-Parish/Parish';
import { useNavigate } from 'react-router-dom';




function ManageDeletedUser() {
 const [parishName, setParishName] = useState('');
 
 const [churchAddress, setChurchAddress] = useState('');
 const [parishName1, setParishName1] = useState('');
 const [churchAddress1, setChurchAddress1] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordGenerating, setPasswordGenerating] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [benLoading, setBenLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  // const [, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData9, setTableData9] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose3 = () => setShow3(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [totalEntries, setTotalEntries] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');
  const [selectedParish, setSelectedParish] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [loaddd, setLoaddd] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [tableData1, setTableData1] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [pageNumber, setPageNumber] = useState('');

  const handleClose2 = () => {
    setShow2(false);
   
  };
  const [show2, setShow2] = useState(false);

  const handleShow2 = (id) => {
    setSelectedUser(id);
    setShow2(true);
  };

  const handleShow3 = (id) => {
    setSelectedUser(id);
    setShow3(true);
  };

  const navigate = useNavigate()

  const handleNewUser = () =>{
    navigate('/create_user')
  }

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    const retrieveStatus = async () => {
      try {
        const modelStatus = await AsyncStorage.getItem('modelUser');
        setIsAdmin(modelStatus === '2');
        // console.log(modelStatus); 
      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };
  
    retrieveStatus();
  }, []);

  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
   setSelectedZone("");
   setSelectedArea("");
   setSelectedParish("");
  }
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
   setSelectedProvince("");
  
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
   setSelectedZone("");
  }
  const handleZoneChange = (event) =>{
    setSelectedZone(event.target.value);
   setSelectedArea("");
    
  }
  
  const handleAreaChange = (event) =>{
    setSelectedArea(event.target.value);
   setSelectedParish("");
  }

  

  //fetch records
  const fetchBeneficiaries = async () => {
    setBenLoading(true);
    try {
        const response = await axios.get(`https://asset-api.patna.ng/api/user/fetch-all-deleted-users?page=${currentPage}`, { headers });
        const results = response.data?.data?.data;
        const resultx = response.data?.data?.total;
        setTotalEntries(resultx);
        setTableData(results);
        console.log(results);
        const total = response.data?.data?.last_page || 1;
        setTotalPages(total);

        
    
    } catch (error) {
        if (error.response && error.response.status === 401) {
            navigate('/login');
        } else {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData([]);
        }
    } finally {
        setBenLoading(false);
    }
};

const fetchUsers = async (searchTerm) => {
  setSearchLoading(true);
  try {
      let results;
      if (searchTerm.trim() === "") {
          results = tableData;
      } else {
          const response = await axios.get(`https://asset-api.patna.ng/api/user/search-deleted-users`, {
              params: { variable: searchTerm },
              headers
          });
          results = response.data?.data;
      }
      console.log(results, "here")
      setSearchResult(results);
  } catch (error) {
      if (error.response && error.response.status === 401) {
          navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
          setSearchResult([]);
      }
  } finally {
      setSearchLoading(false);
  }
};






  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
    }
  }, [bearer, currentPage]);

  

  //create beneficiary
  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/user/create-checker',
        {
          name: fullName,
          email: email,
          phone_number: phone,
          parish_name: parishName,
          church_address:churchAddress
         
        },
        { headers }
      );
   setPhone('');
   setEmail('');
   setFullName('');
   setParishName('');
   setChurchAddress('');
      fetchBeneficiaries();
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

  const handleTransferUser = async () => {
    setTransferLoading(true);
    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/user/transfer',
        {
          continent_id: selectedContinent,
          region_id: selectedRegion,
          area_id: selectedArea,
          province_id: selectedProvince,
          user_id: selectedUser,
          zone_id: selectedZone,
          parish_id: selectedParish

         
        },
        { headers }
      );
      // console.log(response)
     fetchBeneficiaries();
      handleClose2();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      console.log(error);
    } finally {
      setTransferLoading(false);
    }
  };



  //view records
  const handleEyeClick = (id) => {
    const foundUser = tableData.find(item => item.id === id);
    setSelectedUser(foundUser);

    navigate('/edit_user', {state: foundUser})
    setEyeClicked(true);
  };

  const handleEyeClick1 = (id) => {
    const foundUser = searchResult.find(item => item.id === id);
    setSelectedUser(foundUser);

    navigate('/edit_user1', {state: foundUser})
    setEyeClicked(true);
  };


  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/user/force-delete?id=${id}`, { headers });
      fetchBeneficiaries();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };
  //delete function
  const handleRestoreClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to restore this user. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: "No, don't restore it",
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`https://asset-api.patna.ng/api/user/restore-user?id=${id}`, { headers });
      fetchBeneficiaries();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The restoration was cancelled.',
    });
  }
  };

  //update function
  const editUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        'https://asset-api.patna.ng/api/user/update',
        {
          name: fullName1,
          id: deptId, 
          email: email1,
          phone: phone1,
          
        },
        { headers }
      );

      fetchBeneficiaries();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    } finally {
      setLoading(false);
    }
  };

  const handleSendPasswords = async () => {
    setPasswordLoading(true);
    


    try {
      // Send request to send password
      const response = await axios.post(
          `https://asset-api.patna.ng/api/user/passwords`,
          {
            phone_numbers: "08023818035"
          },
          { headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearer}`
            }}
      );
  
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchBeneficiaries();
  
     
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
  
      console.error(error);
    } finally {
      setPasswordLoading(false);
    }
  };
  
  const handleSendPassword = async (phone_number) => {
    setPasswordLoading(true);
    try {
      // Send request to send password
      const response = await axios.post(
          `https://asset-api.patna.ng/api/user/password?phone_number=${phone_number}`,
          {},
          { headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearer}`
            }}
      );
  
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchBeneficiaries();
     
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
  
      console.error(error);
    } finally {
      setPasswordLoading(false);
    }
  };

  const handleGeneratePassword = async (id) => {
    setPasswordGenerating(true);

    try {
      // Send request to send password
      const response = await axios.post(
          `https://asset-api.patna.ng/api/user/default-password`,
          {user_id: selectedUser,
          password: generatedPassword},

          { headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearer}`
            }}
      );
      console.log(selectedUser, generatedPassword);
      console.log(response.data);
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchBeneficiaries();
      handleClose3();
     
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
  
      console.error(error);
    } finally {
      setPasswordGenerating(false);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };
  
 

  //filter function
  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);
   


// console.log(totalEntries);

const handleParishChange = (event) =>{
  setSelectedParish(event.target.value);
  
}

const fetchContinents = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get('https://asset-api.patna.ng/api/continents', { headers });

    // console.log(response);
    const resultsss = response.data?.data;
    setTableData1(resultsss);
    // console.log(results);
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData1([]);
  } finally {
    setIsLoading(false);
  }
};
useEffect(() => {
  if (bearer) {

    fetchContinents();
  }
}, [bearer]);

const fetchSubContinents = async (selectedContinent) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`https://asset-api.patna.ng/api/continents/getcontinentregions?continent_id=${selectedContinent}`, 
    { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
  }, });

    // console.log(response);
    const subCont = response.data?.data;
    setTableData4(subCont);
    // console.log(subCont, "heeeer");
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData4([]);
  } finally {
    setIsLoading(false);
  }
};



const fetchSubRegions = async (selectedRegion) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`https://asset-api.patna.ng/api/regions/getprovincesregion?region_id=${selectedRegion}`, 
    { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
  }, });

    // console.log(response);
    const subReg = response.data?.data;
    setTableData5(subReg);
    // console.log(subCont, "heeeer");
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData5([]);
  } finally {
    setIsLoading(false);
  }
};


const fetchSubProvinces = async (selectedProvince) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`https://asset-api.patna.ng/api/provinces/getzonesprovince?province_id=${selectedProvince}`, 
    { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
  }, });

    // console.log(response);
    const subReg = response.data?.data;
    setTableData6(subReg);
    // console.log(subCont, "heeeer");
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData6([]);
  } finally {
    setIsLoading(false);
  }
};



const fetchSubZones = async (selectedZone) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`https://asset-api.patna.ng/api/zones/getareaszone?zone_id=${selectedZone}`, 
    { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
  }, });

    // console.log(response);
    const subReg = response.data?.data;
    setTableData7(subReg);
    // console.log(subCont, "heeeer");
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData7([]);
  } finally {
    setIsLoading(false);
  }
};

const fetchSubAreas = async (selectedArea) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`https://asset-api.patna.ng/api/areas/getparishesarea?area_id=${selectedArea}`, 
    { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
  }, });

    // console.log(response);
    const subAreaa = response.data?.data;
    setTableData9(subAreaa);
    // console.log(subCont, "heeeer");
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setTableData9([]);
  } finally {
    setIsLoading(false);
  }
};



useEffect(() => {
  if (bearer && selectedContinent) {
    fetchSubContinents(selectedContinent);
  }
}, [bearer, selectedContinent]);

useEffect(() => {
  if (bearer && selectedArea) {
    fetchSubAreas(selectedArea);
  }
}, [bearer, selectedArea]);

useEffect(() => {
  if (bearer && selectedRegion) {
    fetchSubRegions(selectedRegion);
  }
}, [bearer, selectedRegion]);

useEffect(() => {
  if (bearer && selectedProvince) {
    fetchSubProvinces(selectedProvince);
  }
}, [bearer, selectedProvince]);

useEffect(() => {
  if (bearer && selectedZone) {
    fetchSubZones(selectedZone);
  }
}, [bearer, selectedZone]);


  return (
    <ManageDeletedUserUi
      // Pass necessary props from the logic to the UI component
      show={show}
      show1={show1}
      handleShow={handleShow}
      handleShow1={handleShow1}
      handleClose={handleClose}
      handleClose1={handleClose1}
      createUser={createUser}
      editUser={editUser}
      isLoading={isLoading}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      entriesPerPage={entriesPerPage}
      setEntriesPerPage={setEntriesPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalEntries={totalEntries}
      totalPages={totalPages}
      startIndexx={startIndexx}
      endIndexx={endIndexx}
      displayedData={displayedData}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleEyeClick={handleEyeClick}
      handleEyeClick1={handleEyeClick1}
      handleTrashClick={handleTrashClick}
      handleRestoreClick={handleRestoreClick}
      fullName={fullName}
      setFullName={setFullName}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      address={address}
      setAddress={setAddress}
      office_address={office_address}
      setOfficeAddress={setOfficeAddress}
      phone={phone}
      setPhone={setPhone}
      email={email}
      handleSendPassword={handleSendPassword}
      handleSendPasswords={handleSendPasswords}
      passwordLoading={passwordLoading}
      parishName={parishName}
      setParishName={setParishName}
      churchAddress={churchAddress}
      setChurchAddress={setChurchAddress}
      parishName1={parishName1}
      setParishName1={setParishName1}
      churchAddress1={churchAddress1}
      setChurchAddress1={setChurchAddress1}
      setEmail={setEmail}
      fullName1={fullName1}
      setFullName1={setFullName1}
      phone1={phone1}
      setPhone1={setPhone1}
      email1={email1}
      setEmail1={setEmail1}
      tableData={tableData}
      tableData9={tableData9}
      tableData4={tableData4}
      tableData5={tableData5}
      tableData6={tableData6}
      tableData7={tableData7}
      tableData1={tableData1}
      handleContinentChange={handleContinentChange}
      handleProvinceChange={handleProvinceChange}
      handleRegionChange={handleRegionChange}
      handleZoneChange={handleZoneChange}
      handleAreaChange={handleAreaChange}
      isAdmin={isAdmin}
      selectedContinent={selectedContinent}
      selectedArea={selectedArea}
      selectedRegion={selectedRegion}
      selectedZone={selectedZone}
      selectedProvince={selectedProvince}
      handleNewUser = {handleNewUser}
      show2 = {show2}
      handleClose2 = {handleClose2}
      handleShow2 = {handleShow2}
      selectedParish={selectedParish}
      handleParishChange={handleParishChange}
      loaddd={loaddd}
      benLoading={benLoading}
      transferLoading={transferLoading}
      handleTransferUser={handleTransferUser}
      handleShow3={handleShow3}
      handleClose3={handleClose3}
      show3={show3}
  generatedPassword={generatedPassword}
  setGeneratedPassword={setGeneratedPassword}
  passwordGenerating={passwordGenerating}
  setPasswordGenerating={setPasswordGenerating}
  handleGeneratePassword={handleGeneratePassword}
  fetchUsers={fetchUsers}
  searchResult={searchResult}
  searchLoading={searchLoading}

    />
  )
}

export default ManageDeletedUser;